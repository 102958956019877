import React, { useState } from 'react';
// import {Link} from "react-router-dom";
import { Helmet } from "react-helmet-async";

// Images Common File
import * as Img from '../../components/Img';

// Design File
import Commonlayout from "./Commonlayout";


const hidepassSvg = () => {
    return(
    <svg className="gri_password" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
    </svg>
    )
}

const viewpassSvg = () => {
    return(
    <svg className="gri_password" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
    </svg>
    )
}

export default function PagePassword() {

    // View passwords code
    const [showPasswords, setShowPasswords] = useState([false, false, false]);

    const togglePasswordVisibility = (index) => {
        const updatedShowPasswords = [...showPasswords];
        updatedShowPasswords[index] = !updatedShowPasswords[index];
        setShowPasswords(updatedShowPasswords);
    };

  return (
    <Commonlayout>

    <Helmet>
        <title>Password | My Mentor</title>
    </Helmet>

    {/* --- Profile Bx Start --- */}
    <div className="amib-inner-item">
        <div className="amib-768-width mt-4">
            <div className="amib-i-header">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z" />
                </svg>
                Password
            </div>
            <div className="amib-item pb-2">
                <form action="">
                
                    <div className="row pt-1 pt-lg-3">

                        {[0, 1].map((index) => (
                            <div className={`col-sm-6 ${[index] ? 'pe-sm-2' : 'ps-sm-2'}`} key={index}>
                                <div className="signinup-group sgri">
                                    <div className="group__label">{index === 0 ? "New" : "Confirm"} password</div>
                                    <input type={showPasswords[index] ? 'text' : 'password'} placeholder={`Enter ${index === 0 ? "New" : "Confirm"} password`}/>
                                    <span className={`group_right_icon ${showPasswords[index] ? "active" : "" }`} onClick={() => togglePasswordVisibility(index)} >
                                        {showPasswords[index] ? viewpassSvg() : hidepassSvg()}
                                    </span>
                                </div>
                            </div>
                        ))}
                        
                    </div>
                    
                    <div className="amib-save-btn-bx">
                        <button type="button" className="common-submit-btn">Update password</button>
                        <div className="lsb-loader-btn">
                            <img src={Img.loading} alt="Please wait" />Please wait ....
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    {/* --- Profile Bx End --- */}

    </Commonlayout>
  );
};
