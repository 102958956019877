import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { InputField, SelectField } from '../../configue/FormValidator';
import { API_HOST, live_token } from '../../configue/confifigue'
import { useAuth, getLoggedInUserData } from '../../contexts/AuthContext';
import FlashMessage from '../../components/FlashMessage';
import axios from 'axios';
// Images Common File
import * as Img from '../../components/Img';

// Css File
import '../../css/signinup.css'

export default function PageBecomeMentor() {

    const { dispatch, state } = useAuth();
    const navigate = useNavigate();
    const [userId,setUserId] =useState(null); 

    useEffect(() => {
        const loggedInUser = getLoggedInUserData();
        const isUserLoggedIn = !!loggedInUser;

        if (!isUserLoggedIn) {
            navigate('/signin')
        }else{
            setUserId(state.userData.id);
        }
    }, [state.userData]);
    

    const [areaOfWork, setAreaOfWork] = useState('')
    const [experience, setExperience] = useState('');
    const [Organization, setOrganization] = useState('');
    const [reason, setreason] = useState('');
    const [Linkedin, setLinkedin] = useState('');
    const [reference, setReference] = useState('');
   
    const areaOfWorkRef = useRef();
    const experienceRef = useRef();
    const OrganizationRef = useRef();
    const reasonRef = useRef();
    const LinkedinRef = useRef();
    const referenceRef = useRef();


    const [loginSuccsess,setLoginSuccsess] = useState(false);
    

    const handleAreaOfWorkChange = (e) => {
        setAreaOfWork(e.target.value);
    };

    const handleExperienceChange = (e) => {
        setExperience(e.target.value);
    };


    const handleOrganizationChange = (e) => {
        setOrganization(e.target.value);
    };

    const handleReasonChange = (e) => {
        setreason(e.target.value);
    };

    

    const handleLinkedinChange = (e) => {
        setLinkedin(e.target.value);
    };

    const handleReferenceChange = (e) => {
        setReference(e.target.value);
    };

    


    const handleValidation = async () => {
        setLoginSuccsess(false);
        areaOfWorkRef.current.validate();
        experienceRef.current.validate();
        OrganizationRef.current.validate();
        reasonRef.current.validate();
        LinkedinRef.current.validate();
        referenceRef.current.validate();

        if (
            areaOfWorkRef.current.getError() ||
            experienceRef.current.getError() ||
            OrganizationRef.current.getError() ||
            reasonRef.current.getError() ||
            LinkedinRef.current.getError() ||
            referenceRef.current.getError()
        ) {
            return;
        }
if(areaOfWork && experience && Organization && reason ){
        try {
            const token = live_token

            const response = await axios.patch(`${API_HOST}/update-user-data/${userId}/`, {
                user_role:'MENTOR',
                expertise: areaOfWork,
                experience: experience,
                organization: Organization,
                about_me: reason,
                linkedin_profile: Linkedin,
                reference: reference,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response.data) {
                setLoginSuccsess(true);
                localStorage.setItem('userData', JSON.stringify(response.data));
                dispatch({ type: 'LOGIN', payload: response.data });
                setTimeout(() => {
                    navigate('/account');
                }, 5000);
            }
        } catch (error) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.statusText);
            console.log(error.response.headers);
            
        }
    }
    };
    
    const AreaofWorkOptions = [
        { value: '', label: 'Select area of Work' },
        { value: 'Information Technology', label: 'Information Technology' },
        { value: 'Finance', label: 'Finance' },
        { value: 'Healthcare', label: 'Healthcare' },
        { value: 'Education', label: 'Education' },
        { value: 'Manufacturing', label: 'Manufacturing' },
    ];


    return (
        <>
            
            <Helmet>
                <title>Become a Mentor | My Mentor</title>
            </Helmet>

            <div className="login-page-back-effect"></div>
            <div className="sign-in-up-bx">

                <div className='signinup-screen active' id="login-screen">

                    <div className='signinup-header'>
                        <Link to="/" className="back-btn" title="Back to Home">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" color="#F2F1F3" width="20px"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path></svg>
                        </Link>
                        <div>
                            <div className="screen-hed">Do you want to <span>become a Mentor?</span></div>
                            <div className="screen-pera">Fill the details below</div>
                        </div>
                        {/* <img className="signinup-logo" src={Img.logo_color} alt="" /> */}
                    </div>

                    <form className="">

                        <div className="row">


                        <div className="col-6">
                                <div className="signinup-group sgri">
                                    <div className="group__label">Area of Work</div>
                                    <SelectField
                                        name="Area of work"
                                        required={true}
                                        options={AreaofWorkOptions}
                                        value={areaOfWork}
                                        onChange={handleAreaOfWorkChange}
                                        ref={areaOfWorkRef}
                                    />
                               <span className="down-icon"></span>
                                </div>
                            </div>


                            {/* <div className="col-sm-6">
                                <div className="signinup-group">
                                    <div className="group__label">Area of Work</div>
                                    <InputField
                                        name="Area of work"
                                        type="text"
                                        placeholder="Enter area of work"
                                        required={true}
                                        value={areaOfWork}
                                        onChange={handleAreaOfWorkChange}
                                        ref={areaOfWorkRef}
                                    />
                                </div>
                            </div> */}

                            <div className="col-sm-6">
                                <div className="signinup-group">
                                    <div className="group__label">Years of Experience</div>
                                    <InputField
                                        name="Years of Experience"
                                        type="number"
                                        placeholder="Enter Experience"
                                        required={true}
                                        value={experience}
                                        onChange={handleExperienceChange}
                                        ref={experienceRef}
                                    />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="signinup-group">
                                    <div className="group__label">Organization</div>
                                    <InputField
                                        name="Organization"
                                        type="text"
                                        placeholder="Enter organization name"
                                        required={true}
                                        value={Organization}
                                        onChange={handleOrganizationChange}
                                        ref={OrganizationRef}
                                    />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="signinup-group">
                                    <div className="group__label">Why Do you want to become a Mentor</div>
                                    {/* <textarea placeholder="Enter text here" /> */}
                                    <InputField
                                        name="This field"
                                        type="text"
                                        placeholder="Enter text here"
                                        required={true}
                                        value={reason}
                                        onChange={handleReasonChange}
                                        ref={reasonRef}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="signinup-group">
                                    <div className="group__label">Linkedin Profile</div>
                                    <InputField
                                        name="Linkedin Profile"
                                        type="text"
                                        placeholder="Enter social link"
                                        value={Linkedin}
                                        onChange={handleLinkedinChange}
                                        ref={LinkedinRef}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="signinup-group">
                                    <div className="group__label">reference</div>
                                    <InputField
                                        name="reference"
                                        type="text"
                                        placeholder="Enter reference name"
                                        value={reference}
                                        onChange={handleReferenceChange}
                                        ref={referenceRef}
                                    />
                                </div>
                            </div>

                        </div>

                        <button type="button" onClick={handleValidation} className="login-submit-btn">Continue</button>
                        {loginSuccsess && <FlashMessage type="success" isvisible={true} message="Account Updated" />}
                        {/* <div className="lsb-loader-btn">
                            <img src={Img.loading} alt="" />Please wait ....
                        </div> */}

                    </form>

                </div>

            </div>
        </>
    );
};