import React, { useState } from 'react';

const start = () => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
          <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
      </svg>
  )
}

const StarRating = ({ totalStars, initialRating, onRatingChange }) => {
  const [rating, setRating] = useState(initialRating || 0);

  const handleStarClick = (newRating) => {
    setRating(newRating);
    onRatingChange(newRating);
  };

  const handleStarHover = (hoveredRating) => {
    setRating(hoveredRating);
  };

  const handleStarLeave = () => {
    if (initialRating !== undefined) {
      setRating(initialRating);
    } else {
      setRating(0);
    }
  };

  const getStarClassName = (starValue) => {
    if (starValue <= rating) {
      return 'star-icon filled';
    }
    return 'star-icon';
  };

  return (
    <div className="star-rating" onMouseLeave={handleStarLeave}>
      {[...Array(totalStars)].map((_, index) => {
        const starValue = index + 1;
        return (
          <span
            key={index}
            className={getStarClassName(starValue)}
            onClick={() => handleStarClick(starValue)}
            onMouseEnter={() => handleStarHover(starValue)}
          >
            {start()}
          </span>
        );
      })}
    </div>
  );
};

export default StarRating;
