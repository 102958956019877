import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { useAuth, getLoggedInUserData } from '../../contexts/AuthContext';
import { useOption } from '../../contexts/OptionContext';

// Images Common File
import * as Img from '../../components/Img';

// Css File
import '../../css/signinup.css'

export default function PagelookingMentor() {

    const [userLoggedIn, setUserLoggedIn] = useState(false)
    const { dispatch, state } = useAuth();
    const [user, setUser] = useState('');
    const navigate = useNavigate();
    const { selectedOption, setOption } = useOption();
    const [error, setError] = useState(false);

    useEffect(() => {
        const loggedInUser = getLoggedInUserData();
        const isUserLoggedIn = !!loggedInUser;
        if (isUserLoggedIn) {
            setUserLoggedIn(true);
            setUser(loggedInUser)
        }else {
            navigate('/signin')
        }
    }, [state.userData]);

    const handleRadioChange = (event) => {
        setError(false);
        const newOption = event.target.value;
        setOption(newOption);
      };

const handleButtonClick = () => {
        if (!selectedOption) {
            setError(true);
            return;
        }else{
            navigate('/area-of-interest')
        }
    };
    

    return (
        <>
            
            <Helmet>
                <title>Looking a Mentor | My Mentor</title>
            </Helmet>

            <div className="login-page-back-effect"></div>
            <div className="sign-in-up-bx">

                <div className='signinup-screen active' id="login-screen">

                    <div className='signinup-header'>
                        <Link to="/" className="back-btn" title="Back to Home">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" color="#F2F1F3" width="20px"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path></svg>
                        </Link>
                        <div>
                            <div className="screen-hed">Are you <span>looking</span> for a Mentor?</div>
                            <div className="screen-pera">Select any of the details below</div>
                        </div>
                        {/* <img className="signinup-logo" src={Img.logo_color} alt="" /> */}
                    </div>

                    <form className="">

                        <div className="row">


                            <div className="col-12">
                                <div className="signinup-group">
                                    <div className="signinup-group-radio sgr-flex-column">

                                        <input type="radio" value="5 to 7th Std"  onChange={handleRadioChange}  id="lm1" name="lm" hidden/>
                                        <label htmlFor="lm1"><span className="sgr-icon"></span>5 to 7th Std</label>

                                        <input type="radio" value="8 to 10th Std" onChange={handleRadioChange} id="lm2" name="lm" hidden/>
                                        <label htmlFor="lm2"><span className="sgr-icon"></span>8 to 10th Std</label>

                                        <input type="radio" value="11 to 12th Std"  onChange={handleRadioChange} id="lm3" name="lm" hidden/>
                                        <label htmlFor="lm3"><span className="sgr-icon"></span>11 to 12th Std</label>

                                        <input type="radio" value="Working Professional"  onChange={handleRadioChange} id="lm4" name="lm" hidden/>
                                        <label htmlFor="lm4"><span className="sgr-icon"></span>Working Professional</label>
                                        {error && <span className="form-error-msg">Please select atleast one option</span>}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <button type="button" onClick={handleButtonClick} className="login-submit-btn">Continue</button>

                        {/* <Link to="/area-of-interest" className="lsb-loader-btn">
                            <img src={Img.loading} alt="" />Please wait ....
                        </Link> */}

                    </form>

                </div>

            </div>
        </>
    );
};