import React from 'react';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

// Images Common File
// import * as Img from '../../components/Img';
import signup from '../../img/icons/signup.png';
import collaborate from '../../img/icons/collaborate.png';
import Improve from '../../img/icons/Improve.png';

import TestimonialsSlider from '../Slider/TestimonialsSlider';

export default function PageAbout() {
  return (
    <>
    <Helmet>
        <title>About | My Mentor</title>
    </Helmet>
      
    <section>
        <div className="breadcrumb-outer">
            <div className="container-lg cl-custom">
                <div className="breadcrumb-bx">
                    <Link className="breadcrumb-link" to="/">Home<i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                    <Link className="breadcrumb-link breadcrumb-active">About</Link>
                </div>
            </div> 
        </div>
    </section>

    <section className="py-5">
        <div className="container-lg cl-custom pt-0 pt-md-4 pb-4">

              <div className="row justify-content-center">
                {/* <div className="col-lg-4 col-md-5">
                  <div className="common-img-bx">
                    <img className="p-0" src={Img.logo_color} alt="" />
                  </div>
                </div> */}
                <div className="col-lg-8 text-center">
                {/* <div className="col-lg-7 col-md-6"> */}
                  <div className="common-heading-bx">
                    <div className="common-top-heading">About Us</div>
                    <div className="common-heading">The experts in local and international mentors in all industries</div>
                    <div className="common-sub-heading">Agent hen an unknown printer took a galley of type and scramble it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic.</div>
                  </div>

                  <ul className="common-date-ul cdu-flex cdu-no-style mt-3">
                    <li>Outstanding Mentos</li>
                    <li>More than 200+ Locations</li>
                    <li>Specialised Courses</li>
                    <li>Market-leading research</li>
                  </ul>

                </div>
            </div>

        </div>
    </section>

    <section className="d-none py-5 bg-color-lightgray">
        <div className="container-lg cl-custom py-4">
            <div className="common-heading-bx text-center">
                <div className="common-top-heading">MENTORING FLOW</div>
                <div className="common-heading">How does it works ?</div>
                <div className="common-sub-heading">Are you looking to join online institutions? Now it's very simple, Sign up with mentoring</div>
            </div>

            <div className="row justify-content-around mt-5">
              
                <div className="col-md-3 mt-0 mt-md-5">
                    <div className="works-card-item">
                      <div className="wci-img wci-color1">
                        <img src={signup} alt="" />
                      </div>
                      <div className="wci-heading">Sign up</div>
                      <div className="wci-text">Are you looking to join online Learning? Now it's very simple, Now Sign up</div>
                    </div>
                </div>

                <div className="col-md-3 mt-5 mt-md-0">
                    <div className="works-card-item">
                      <div className="wci-img wci-color2">
                        <img src={collaborate} alt="" />
                      </div>
                      <div className="wci-heading">Collaborate</div>
                      <div className="wci-text">Collaborate on your own timing, by scheduling with mentor booking</div>
                    </div>
                </div>

                <div className="col-md-3 mt-5">
                    <div className="works-card-item">
                      <div className="wci-img wci-color3">
                        <img src={Improve} alt="" />
                      </div>
                      <div className="wci-heading">Improve & Get Back</div>
                      <div className="wci-text">you can gather different skill set, and you can become mentor too</div>
                    </div>
                </div>

            </div>
        
        </div>
    </section>

    <section className="d-none py-5">
        <div className="container-lg cl-custom relative py-5">
            <div className="common-heading-bx text-center">
                <div className="common-top-heading">What our Students are saying About us?</div>
                <div className="common-heading">Testimonials & Reviews</div>
            </div>
            <TestimonialsSlider />
        </div>
    </section>
    </>
  )
}
