import React from 'react';
import {Link} from "react-router-dom";
import { Helmet } from "react-helmet-async";

// Images Common File
import * as Img from '../../components/Img';

// Css File
import '../../css/signinup.css';


export default function PageForgotPassword() {

    return(
        <>
            <Helmet>
                <title>Forgot Password | My Mentor</title>
            </Helmet>

            <div className="login-page-back-effect"></div>
            <div className="sign-in-up-bx">
    
                <div className='signinup-screen active' id="forgotpassword-screen">

                    <div className='signinup-header'>
                        <Link to="/signin" className="back-btn" title="Back to Login">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" color="#F2F1F3" width="20px"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path></svg>
                        </Link>
                        <div>
                            <div className="screen-hed">Forgotten password?</div>
                            <div className="screen-pera">Enter your email to get a password reset link</div>
                        </div>
                        {/* <img className="signinup-logo" src={Img.logo_color} alt="" /> */}
                    </div>


                    <form>

                        <div className="row">
    
                            <div className="col-12">
                                <div className="signinup-group">
                                    <div className="group__label">Your email id</div>
                                    <input type="email" placeholder="you@example.com"/>
                                </div>
                            </div>
                        
                        </div>

                        <button type="button"  className="login-submit-btn">Find Account</button>

                        {/* <div className="lsb-loader-btn">
                            <img src={Img.loading} alt="" />Please wait ....
                        </div> */}

                        <div className="signinuptogleblink">
                            Remember your password?
                            <Link to='/signin' className="siulink">Back to login</Link>
                        </div>

                    </form>

                </div>

            </div>
        </>
    );
};