import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { InputField, SelectField } from '../../configue/FormValidator';
import { API_HOST, live_token, imageUrl } from '../../configue/confifigue'
import { useAuth, getLoggedInUserData } from '../../contexts/AuthContext';
import FlashMessage from '../../components/FlashMessage';
import axios from 'axios';

// Images Common File
import * as Img from '../../components/Img';

// Design File
import Commonlayout from "./Commonlayout";


export default function PageAccount() {

    const [userLoggedIn, setUserLoggedIn] = useState(false)
    const { dispatch, state } = useAuth();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState('');
    const navigate = useNavigate();


    const [allreadyProfile,setAllreadyProfile] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [fileError, setFileError] = useState(false);
    const [gender, setGender] = useState('')
    const [firstName, setFirstname] = useState('');
    const [lastName, setLastname] = useState('');
    const [phoneNumber, setPhonenumber] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [pinCode, setPinCode] = useState('');


    const [charges, setCharges] = useState('');
    const [areaOfWork, setAreaOfWork] = useState('')
    const [experience, setExperience] = useState('');
    const [Organization, setOrganization] = useState('');
    const [Linkedin, setLinkedin] = useState('');
    const [reason, setreason] = useState('');
    const [reference, setReference] = useState('');

    const chargesRef = useRef();
    const areaOfWorkRef = useRef();
    const experienceRef = useRef();
    const OrganizationRef = useRef();
    const reasonRef = useRef();
    const LinkedinRef = useRef();
    const referenceRef = useRef();
  
    const genderRef = useRef();
    const firstnameRef = useRef();
    const lastnameRef = useRef();
    const phoneNumberRef = useRef();
    const emailRef = useRef();
    const addressRef = useRef();
    const cityRef = useRef();
    const countryRef = useRef();
    const pincodeRef = useRef();

    const [emailError,setEmailError] = useState(false);
    const [phoneNumberError,setphoneNumberError] = useState(false);
    
    
    const [loginSuccsess,setLoginSuccsess] = useState(false);

    

    useEffect(() => {
        const loggedInUser = getLoggedInUserData();
        const isUserLoggedIn = !!loggedInUser;
        if (isUserLoggedIn) {
            setUserLoggedIn(true);
            setUser(loggedInUser);

            const baseUrl1 = 'http://127.0.0.1:8000';
            const baseUrl2 = 'https://mymentorbackend.pmcommu.in';

            const profilePicUrl = loggedInUser.profile_pic
                ? loggedInUser.profile_pic.startsWith(baseUrl1) || loggedInUser.profile_pic.startsWith(baseUrl2)
                    ? loggedInUser.profile_pic
                    : `${imageUrl}${loggedInUser.profile_pic}`
                : null;
            setSelectedFile(profilePicUrl);
            setAllreadyProfile(!!selectedFile);
            setGender(loggedInUser.gender || '');
            setFirstname(loggedInUser.first_name || '');
            setLastname(loggedInUser.last_name || '');
            setPhonenumber(loggedInUser.phone_number || '');
            setEmail(loggedInUser.email || '');
            setAddress(loggedInUser.address || '');
            setCity(loggedInUser.city || '');
            setCountry(loggedInUser.country || '');
            setPinCode(loggedInUser.pincode || '');
            setCharges(loggedInUser.price || '');
            setAreaOfWork(loggedInUser.expertise || '');
            setExperience(loggedInUser.experience || '');
            setOrganization(loggedInUser.organization || '');
            setLinkedin(loggedInUser.linkedin_profile || '');
            setreason(loggedInUser.about_me || '');
            setReference(loggedInUser.reference || '');


        }else {
            navigate('/signin')
        }
    }, [state.userData]);
   
   
   

    const handleFileChange = (e) => {
        setFileError(false);
        setAllreadyProfile(false);
        const file = e.target.files[0];
        setSelectedFile(file);

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setPreviewUrl(null);
        }
    };

    const handleGenderChange = (e) => {
        setGender(e.target.value);
    };


    const handleFirstNameChange = (e) => {
        setFirstname(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastname(e.target.value);
    };

    const handlePhoneNumberChange = (e) => {
        setPhonenumber(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    };


    const handleCityChange = (e) => {
        setCity(e.target.value);
    };


    const handleCountryChange = (e) => {
        setCountry(e.target.value);
    };

    const handlePinCodeChange = (e) => {
        setPinCode(e.target.value);
    };


    const handleAreaOfWorkChange = (e) => {
        setAreaOfWork(e.target.value);
    };

    const handleExperienceChange = (e) => {
        setExperience(e.target.value);
    };


    const handleOrganizationChange = (e) => {
        setOrganization(e.target.value);
    };

    const handleReasonChange = (e) => {
        setreason(e.target.value);
    };

    

    const handleLinkedinChange = (e) => {
        setLinkedin(e.target.value);
    };

    const handleReferenceChange = (e) => {
        setReference(e.target.value);
    };


const handleChargesChange = (e) => {
        setCharges(e.target.value);
    };


    
    const handleValidationTwo = async () => {
        setLoginSuccsess(false);
        chargesRef.current.validate();
        areaOfWorkRef.current.validate();
        experienceRef.current.validate();
        OrganizationRef.current.validate();
        reasonRef.current.validate();
        LinkedinRef.current.validate();
        referenceRef.current.validate();

        if (
            chargesRef.current.getError() ||
            areaOfWorkRef.current.getError() ||
            experienceRef.current.getError() ||
            OrganizationRef.current.getError() ||
            reasonRef.current.getError() ||
            LinkedinRef.current.getError() ||
            referenceRef.current.getError()
        ) {
            return;
        }
        if ( charges && areaOfWork && experience && Organization && reason) {

       
        try {
            const token = live_token
           
            const response = await axios.patch(`${API_HOST}/update-user-data/${user.id}/`, {
                user_role:'MENTOR',
                price:charges,
                expertise: areaOfWork,
                experience: experience,
                organization: Organization,
                about_me: reason,
                linkedin_profile: Linkedin,
                reference: reference,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response.data) {
                setLoginSuccsess(true);
                localStorage.setItem('userData', JSON.stringify(response.data));
                dispatch({ type: 'LOGIN', payload: response.data });
                setTimeout(() => {
                    navigate('/account');
                }, 5000);
            }
        } catch (error) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.statusText);
            console.log(error.response.headers);
            
        }
    }
    };


    const genderOptions = [
        { value: '', label: 'Select gender' },
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
    ];


    const cityOptions = [
        { value: '', label: 'Select city' },
        { value: 'Ahmedabad', label: 'Ahmedabad' },
        { value: 'Mumbai', label: 'Mumbai' },
        { value: 'Delhi', label: 'Delhi' },
    ];


    const countryOptions = [
        { value: '', label: 'Select country' },
        { value: 'India', label: 'India' },
        { value: 'Usa', label: 'Usa' },
        { value: 'Uk', label: 'Uk' },
    ];

    const handleValidation = async () => {
        setLoginSuccsess(false);
        genderRef.current.validate();
        firstnameRef.current.validate();
        lastnameRef.current.validate();
        phoneNumberRef.current.validate();
        emailRef.current.validate();
        addressRef.current.validate();
        cityRef.current.validate();
        countryRef.current.validate();
        pincodeRef.current.validate();

      
        if (!allreadyProfile && !selectedFile) {
            setFileError(true);
            return;
        }

       
        if (
            genderRef.current.getError() ||
            firstnameRef.current.getError() ||
            lastnameRef.current.getError() ||
            phoneNumberRef.current.getError() ||
            emailRef.current.getError() ||
            addressRef.current.getError() ||
            cityRef.current.getError() ||
            countryRef.current.getError() ||
            pincodeRef.current.getError()
        ) {
            return;
        }
        if ((selectedFile || allreadyProfile) && gender && firstName && lastName && phoneNumber && email && address && city && country && pinCode) {
            
            setEmailError(false);
            setphoneNumberError(false);
            
            const formData = new FormData();
            if(!allreadyProfile){
                formData.append('profile_pic', selectedFile);
            } 
            formData.append('gender', gender);
            formData.append('first_name', firstName);
            formData.append('last_name', lastName);
            formData.append('phone_number', phoneNumber);
            formData.append('email', email);
            formData.append('address', address);
            formData.append('city', city);
            formData.append('country', country);
            formData.append('pincode', pinCode);

            try {
                const token = live_token;

                const response = await axios.patch(`${API_HOST}/update-user/${user.id}/`, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.data) {
                    setLoginSuccsess(true);
                    localStorage.setItem('userData', JSON.stringify(response.data));
                    dispatch({ type: 'LOGIN', payload: response.data });
                }
            } catch (error) {
                
                if (
                    error.response.status === 400 &&
                    error.response.data.email &&
                    error.response.data.email.length > 0
                ) {
                   setEmailError(true);
                } 
                
                if (
                    error.response.status === 400 &&
                    error.response.data.phone_number &&
                    error.response.data.phone_number.length > 0
                ) {
                   setphoneNumberError(true);
                }
                
            }
        }
    };

    const AreaofWorkOptions = [
        { value: '', label: 'Select area of Work' },
        { value: 'Information Technology', label: 'Information Technology' },
        { value: 'Finance', label: 'Finance' },
        { value: 'Healthcare', label: 'Healthcare' },
        { value: 'Education', label: 'Education' },
        { value: 'Manufacturing', label: 'Manufacturing' },
    ];

    // // Input Field js
    // const [isbodShow, setisbodShow] = useState(true);
    // const [bodValue, setbodValue] = useState('');

    // const handlebodChange = (e) => {
    //     const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    //     const formattedValue = formatbod(value);
    //     setisbodShow(value === '');
    //     setbodValue(formattedValue);
    // };

    // const formatbod = (value) => {
    //     if (value.length === 0) return '';
    //     if (value.length <= 2) return value;
    //     if (value.length <= 4) return `${value.slice(0, 2)}/${value.slice(2)}`;
    //     return `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4, 8)}`;
    // };

    // const handlebodClear = () => {
    //     setbodValue("");
    //     setisbodShow(true)
    // };

    return (
        <Commonlayout>

            <Helmet>
                <title>Account | My Mentor</title>
            </Helmet>

            {/* --- Profile Bx Start --- */}
            <div className="amib-inner-item">
                <div className="amib-768-width mt-4">
                    <div className="amib-i-header">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M258.9 48C141.92 46.42 46.42 141.92 48 258.9c1.56 112.19 92.91 203.54 205.1 205.1 117 1.6 212.48-93.9 210.88-210.88C462.44 140.91 371.09 49.56 258.9 48zm126.42 327.25a4 4 0 01-6.14-.32 124.27 124.27 0 00-32.35-29.59C321.37 329 289.11 320 256 320s-65.37 9-90.83 25.34a124.24 124.24 0 00-32.35 29.58 4 4 0 01-6.14.32A175.32 175.32 0 0180 259c-1.63-97.31 78.22-178.76 175.57-179S432 158.81 432 256a175.32 175.32 0 01-46.68 119.25z" /><path fill="currentColor" d="M256 144c-19.72 0-37.55 7.39-50.22 20.82s-19 32-17.57 51.93C191.11 256 221.52 288 256 288s64.83-32 67.79-71.24c1.48-19.74-4.8-38.14-17.68-51.82C293.39 151.44 275.59 144 256 144z" /></svg>
                        Profile
                    </div>
                    <div className="amib-item pb-2">
                        <form action="" encType="multipart/form-data" method="PATCH">

                            <div className="row pt-1 pt-lg-3">

                                <div className="col-12 mb-3">
                                    <div className="profile-pic-bx">
                                        <label htmlFor="profile-photo" className="profile-pic-lable">
                                            <img
                                                className="profile-pic-img"
                                                src={previewUrl || selectedFile || Img.user}
                                                alt="Profile Pic"
                                            />
                                            <div className="profile-upload">Upload</div>
                                        </label>

                                        <label title="Edit Pic" className="profile-pic-btn edit-btn" htmlFor="profile-photo">
                                            <i className="fa fa-pencil-square-o"></i>
                                        </label>
                                        <div title="Remove Pic" className="profile-pic-btn delete-btn">
                                            <i className="fa fa-trash-o"></i>
                                        </div>
                                        <input
                                            type="file"
                                            name="profile-photo"
                                            id="profile-photo"
                                            onChange={handleFileChange}
                                            hidden
                                        />
                                    </div>
                                </div>
                                {fileError && <span className="form-error-msg">Profile image is required</span>}

                                <div className="col-sm-4 col-6">
                                    <div className="signinup-group sgri">
                                        <div className="group__label">Gender</div>
                                        <SelectField
                                            name="Gender"
                                            required
                                            options={genderOptions}
                                            value={gender}
                                            onChange={handleGenderChange}
                                            ref={genderRef}
                                        />
                                        <span className="down-icon"></span>
                                    </div>
                                </div>


                                <div className="col-12"></div>

                                <div className="col-6">
                                    <div className="signinup-group">
                                        <div className="group__label">First Name</div>
                                        <InputField
                                            name="Firstname"
                                            type="text"
                                            placeholder="Enter first name"
                                            required={true}
                                            value={firstName}
                                            onChange={handleFirstNameChange}
                                            ref={firstnameRef}
                                        />
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="signinup-group">
                                        <div className="group__label">Last Name</div>
                                        <InputField
                                            name="Lastname"
                                            type="text"
                                            placeholder="Enter last name"
                                            required={true}
                                            value={lastName}
                                            onChange={handleLastNameChange}
                                            ref={lastnameRef}
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="signinup-group">
                                        <div className="group__label">Phone Number</div>
                                        <InputField
                                            name="Phonenumber"
                                            type="number"
                                            placeholder="Enter phone number"
                                            required={true}
                                            value={phoneNumber}
                                            onChange={handlePhoneNumberChange}
                                            ref={phoneNumberRef}
                                        />
                                        {phoneNumberError && <span className="form-error-msg">Phone number alreay exist</span>}
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="signinup-group">
                                        <div className="group__label">Your email</div>
                                        <InputField
                                            name="Email"
                                            type="email"
                                            placeholder="you@example.com"
                                            required={true}
                                            value={email}
                                            onChange={handleEmailChange}
                                            ref={emailRef}
                                        />
                                        {emailError && <span className="form-error-msg">Email alreay exist</span>}
                                        {/* <span className="form-error-msg">You must provide an email</span> */}
                                    </div>
                                </div>
                              
                                {/* <div className="col-6">
                            <div className="signinup-group">
                                <div className="group__label">Date of birth</div>
                                <input type="text" inputMode="numeric"  value={bodValue}  onChange={handlebodChange} placeholder="DD/MM/YYYY"/>
                                <span className="clear-input-icon" hidden={isbodShow} onClick={handlebodClear}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="38" d="M368 368L144 144M368 144L144 368"/></svg>
                                </span>
                            </div>
                        </div> */}

                                <div className="col-12">
                                    <div className="signinup-group">
                                        <div className="group__label">Address</div>
                                        {/* <textarea placeholder="Enter address" /> */}
                                        <InputField
                                            name="Address"
                                            type="text"
                                            placeholder="Enter address"
                                            required={true}
                                            value={address}
                                            onChange={handleAddressChange}
                                            ref={addressRef}
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-4 col-6">
                                    <div className="signinup-group sgri">
                                        <div className="group__label">City</div>
                                        <SelectField
                                            name="City"
                                            required
                                            options={cityOptions}
                                            value={city}
                                            onChange={handleCityChange}
                                            ref={cityRef}
                                        />
                                        <span className="down-icon"></span>
                                    </div>
                                </div>

                                <div className="col-sm-4 col-6">
                                    <div className="signinup-group sgri">
                                        <div className="group__label">Country</div>
                                        <SelectField
                                            name="Country"
                                            required
                                            options={countryOptions}
                                            value={country}
                                            onChange={handleCountryChange}
                                            ref={countryRef}
                                        />
                                        <span className="down-icon"></span>
                                    </div>
                                </div>

                                <div className="col-sm-4 col-6">
                                    <div className="signinup-group">
                                        <div className="group__label">Pincode</div>
                                        <InputField
                                            name="Pin code"
                                            type="number"
                                            placeholder="Enter pincode"
                                            required={true}
                                            value={pinCode}
                                            onChange={handlePinCodeChange}
                                            ref={pincodeRef}
                                        />
                                    </div>
                                </div>



                            </div>
                            
                        
                            {loginSuccsess && <FlashMessage type="success" isvisible={true} message="Account Updated" />}
                            <div className="amib-save-btn-bx">
                                <button type="button" onClick={handleValidation} className="common-submit-btn">Update account</button>
                                {/* <div className="lsb-loader-btn">
                            <img src={Img.loading} alt="Please wait" />Please wait ....
                        </div> */}
                            </div>
                        </form>
                    </div>
                </div>
                {user.user_role === 'MENTOR' && (
                <div className="amib-768-width mt-5">
                    <div className="amib-i-header">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                        </svg>

                        Other details
                    </div>
                    <div className="amib-item pb-2">
                        <form action="">

                            <div className="row pt-1 pt-lg-3">

                                <div className="col-6">
                                    <div className="signinup-group sgli">
                                        <div className="group__label">Mentoring charges</div>
                                        <InputField
                                        name="amount"
                                        type="text"
                                        placeholder="Enter amount"
                                        required={true}
                                        value={charges}
                                        onChange={handleChargesChange}
                                        ref={chargesRef}
                                    />
                                        <span className="group_left_icon">$</span>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="signinup-group">
                                        <div className="group__label">Area of Work</div>
                                        <SelectField
                                        name="Area of work"
                                        required={true}
                                        options={AreaofWorkOptions}
                                        value={areaOfWork}
                                        onChange={handleAreaOfWorkChange}
                                        ref={areaOfWorkRef}
                                    />
                                     <span className="down-icon"></span>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="signinup-group">
                                        <div className="group__label">Years of Experience</div>
                                        <InputField
                                        name="Years of Experience"
                                        type="number"
                                        placeholder="Enter Experience"
                                        required={true}
                                        value={experience}
                                        onChange={handleExperienceChange}
                                        ref={experienceRef}
                                    />
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="signinup-group">
                                        <div className="group__label">Organization</div>
                                        <InputField
                                        name="Organization"
                                        type="text"
                                        placeholder="Enter organization name"
                                        required={true}
                                        value={Organization}
                                        onChange={handleOrganizationChange}
                                        ref={OrganizationRef}
                                    />
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="signinup-group">
                                        <div className="group__label">Linkedin Profile</div>
                                        <InputField
                                        name="Linkedin Profile"
                                        type="text"
                                        placeholder="Enter social link"
                                        value={Linkedin}
                                        onChange={handleLinkedinChange}
                                        ref={LinkedinRef}
                                    />
                                    </div>
                                </div>

                                {/* <div className="col-12">
                                    <div className="signinup-group">
                                        <div className="group__label">About you</div>
                                        <textarea placeholder="Enter text here..." />
                                    </div>
                                </div> */}

                                <div className="col-12">
                                    <div className="signinup-group">
                                        <div className="group__label">Why Do you wan to become a Mentor</div>
                                        <InputField
                                        name="This field"
                                        type="text"
                                        placeholder="Enter text here"
                                        required={true}
                                        value={reason}
                                        onChange={handleReasonChange}
                                        ref={reasonRef}
                                    />
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="signinup-group">
                                        <div className="group__label">Refence</div>
                                        <InputField
                                        name="reference"
                                        type="text"
                                        placeholder="Enter reference name"
                                        value={reference}
                                        onChange={handleReferenceChange}
                                        ref={referenceRef}
                                    />
                                    </div>
                                </div>

                            </div>

                            <div className="amib-save-btn-bx">
                                <button onClick={handleValidationTwo} type='button' className="common-submit-btn">Update details</button>
                                {/* <div className="lsb-loader-btn">
                            <img src={Img.loading} alt="Please wait" />Please wait ....
                        </div> */}
                            </div>
                        </form>
                    </div>
                </div>)}

            </div>
            {/* --- Profile Bx End --- */}

        </Commonlayout>
    );
};
