import React, { useState, useEffect, forwardRef } from 'react';
import '../css/signinup.css'


const InputField = forwardRef(({ name, type, required,placeholder, pattern, errorMessage, value, onChange, onBlur }, ref) => {
  const [error, setError] = useState('');

  useEffect(() => {
    const validate = () => {
      if (required && !value.trim()) {
        setError(name + ' ' +'is required');
      } else if (type === 'email' && !isValidEmail(value)) {
        setError('Invalid email address');
      } else if (pattern && !RegExp(pattern).test(value)) {
        setError(errorMessage || 'Invalid input');
      } else {
        setError('');
      }
    };

    const getValue = () => value;

    if (ref) {
      ref.current = { validate, getError: () => error, getValue, clearError: () => setError('') };
    }

    return () => {
    };
  }, [ref, value, required, pattern, errorMessage, error, type]);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    onChange(e);
    ref.current.clearError();
  };

  return (
    <div>
      <input type={type} value={value} onChange={handleChange} placeholder={placeholder} onBlur={onBlur || (() => {})} />
      {error && <span className="form-error-msg">{error}</span>}
    </div>
  );
});





const SelectField = forwardRef(({ name, required, options, value, onChange, onBlur }, ref) => {
  const [error, setError] = useState('');

  useEffect(() => {
    const validate = () => {
      if (required && value === '') {
        setError(`${name} is required`);
      } else {
        setError('');
      }
    };

    const getValue = () => value;

    if (ref) {
      ref.current = {
        validate: validate,
        getError: () => error,
        getValue,
        clearError: () => setError(''),
      };
    }

    return () => {};
  }, [ref, value, required, error, name]);

  const handleChange = (e) => {
    onChange(e);
    ref.current.clearError();
  };

  return (
    <div>
      <select value={value} onChange={handleChange} onBlur={onBlur || (() => {})}>
        <option value="" disabled hidden>
           {name}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <span className="form-error-msg">{error}</span>}
    </div>
  );
});

export { SelectField };

export { InputField };
