import React, { useState, useEffect } from 'react';
import { API_HOST, live_token, imageUrl } from '../configue/confifigue'
import axios from 'axios';
//Design Files
import Starrating from './StarRating';

// Images Common File
import * as Img from './Img';

function Commonpopup(appointmentId) {
    const [rating, setRating] = useState(0);
    // const [modalShown, setModalShown] = useState(false);
    // const [appointmentOptions, setAppointmentOptions] = useState([]);

    const handleRatingChange = (newRating) => {
        setRating(newRating);
    };



    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const API_ENDPOINT = `${API_HOST}/all-appointments/${appointmentId.appointmentId}`;
    //             const HEADERS = {
    //                 'Authorization': `Bearer ${live_token}`,
    //                 'Content-Type': 'application/json',
    //             };

    //             const response = await axios.get(API_ENDPOINT, {
    //                 headers: HEADERS,
    //             });
                
    //             setAppointmentOptions(response.data);
    //             setModalShown(false);

    //         } catch (error) {
    //             console.error('API request error:', error);
    //         }
    //     };

    //     if (appointmentId && modalShown) {
    //         fetchData();
    //     }
    // }, [appointmentId, modalShown]);


    // const handleShowModal = () => {
    //     setModalShown(true);
    // };

    // useEffect(() => {
    //     console.log('appointmentOptions:', appointmentOptions);
    //   }, [appointmentOptions]);
      

    return (
        <>
            {/* Share Popup */}
            {/* <div className="modal fade zoom" id="sharemodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content custom-content">
                        <div className="custom-modal-header">
                            <div className="cmh-lable">Share vai</div>

                            <span className="close-icon" data-bs-dismiss="modal" aria-label="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                                </svg>
                            </span>
                        </div>
                        <div className="custom-modal-data">
                            <div className="social-share-icon">
                                <a className="ssi-item fb-item" href="/" target="_blank">
                                    <span className='ssi-icon'>
                                        <i className="fa fa-facebook" aria-hidden="true"></i>
                                    </span>
                                    <div className='ssi-lable'>Facebook</div>
                                </a>
                                <a className="ssi-item insta-item" href="/" target="_blank">
                                    <span className='ssi-icon'>
                                        <i className="fa fa-instagram" aria-hidden="true"></i>
                                    </span>
                                    <div className='ssi-lable'>Instagram</div>
                                </a>
                                <a className="ssi-item whatsapp-item" href="/" target="_blank">
                                    <span className='ssi-icon'>
                                        <i className="fa fa-whatsapp" aria-hidden="true"></i>
                                    </span>
                                    <div className='ssi-lable'>Whatsapp</div>
                                </a>
                                <a className="ssi-item copy-item" href="/" target="_self">
                                    <span className='ssi-icon'>
                                        <i className="fa fa-link" aria-hidden="true"></i>
                                    </span>
                                    <div className='ssi-lable'>Copy Link</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}


            {/* <div data-bs-toggle="modal" onClick={handleShowModal} data-bs-target="#Appointmentdetail" className="common-btn-item cbi-fill cb-small">
                <span>
                    Details
                </span>
            </div> */}
            {/* Appointment detail Popup */}
            <div className="modal fade zoom" id="Appointmentdetail" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content custom-content">
                        <div className="custom-modal-header">
                            <div className="cmh-lable">Appointment Detail</div>
                            <span className="close-icon" data-bs-dismiss="modal" aria-label="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                                </svg>
                            </span>
                        </div>
                        <div className="custom-modal-data">

                            <div className="row mx-0 px-2 pt-1">

                                <div className='col-12 mb-2'>
                                    <div className="orderdetail-data">Transaction Id<br /><span>#2556556555</span></div>
                                </div>


                                <div className='col-12 mb-2'>
                                    <div className="orderdetail-data">Booking Date<br /><span>19 Feb 2024</span></div>
                                </div>

                                <div className="col-12 mt-4">
                                    <div className="common-heading-bx chb-two">
                                        <div className="common-heading">Selected Slot </div>
                                    </div>
                                    <table className="custom-table">
                                        <thead>
                                            <tr>
                                                <th>No.</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>29/02/2024</td>
                                                <td>09:30 AM</td>
                                            </tr>
                                            {/* <tr>
                                                <td>2</td>
                                                <td>01/03/2024</td>
                                                <td>11:30 AM</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>03/03/2024</td>
                                                <td>05:30 PM</td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="col-12 my-4">
                                    <div className="summary-bx sb-static">
                                        <div className="summary-heading">
                                            Payment Details
                                        </div>
                                        <div className="summary-data-bx">
                                            <div className="summary-data-item">
                                                <div className="sdi-inner sdi-img">
                                                    <img src={Img.user} alt="" />
                                                    <div>Mentor Name <span>Rs. 499.00</span> </div>
                                                </div>
                                            </div>
                                            {/* <div className="summary-data-item">
                                                <div className="sdi-inner">
                                                    <div>Service fee</div>
                                                    <div>Rs. 100000</div>
                                                </div>
                                                <div className="sdi-inner">
                                                    <div>Sales tax</div>
                                                    <div>Free</div>
                                                </div>
                                            </div> */}
                                            <div className="summary-data-item">
                                                <div className="sdi-inner sdi-total">
                                                    <div>Total</div>
                                                    <div>Rs. 499.00</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Commonpopup;