import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useAuth, getLoggedInUserData } from '../../contexts/AuthContext';

// Images Common File
// import * as Img from '../../components/Img';


export default function PageHome() {

  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const { dispatch, state } = useAuth();
  const [user, setUser] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const loggedInUser = getLoggedInUserData();
    const isUserLoggedIn = !!loggedInUser;

    if (isUserLoggedIn) {
      setUserLoggedIn(true);
      setUser(loggedInUser)
    }
  }, [state.userData]);


  const handleLinkClick = (path) => {
    if (!userLoggedIn) {
      navigate('/signin');
    } else {
      navigate(path);
    }
  };

  return (
    <>
      <Helmet>
        <title>My Mentor</title>
      </Helmet>

      <section className="bg-color-e6">
        <div className="container-lg cl-custom">
          <div className="top-top-heading">
            We help you shape your career by giving <br />
            the mentor ship by industry professionals
          </div>
        </div>
      </section>

      <section className="top-section py-5 bg-img-three">
        <div className="container-lg cl-custom py-4">
          <div className="row justify-content-end">
            <div className='col-lg-5 col-md-6 col-12 text-center text-sm-end'>
              <div className='vdhvbs'>What are you looking for?</div>
              <div
                className="ms-sm-auto safjehbgfvehjb radio-50px"
                onClick={() => handleLinkClick('/looking-mentor')}
              >
                I am looking for a Mentor
              </div>
              <div>
                {((userLoggedIn && user.user_role !== 'MENTOR') || (!userLoggedIn))  && (
                  <div
                    to=""
                    className="ms-sm-auto safjehbgfvehjb radio-50px"
                    onClick={() => handleLinkClick('/become-mentor')}
                  >
                    I want to become a Mentor
                  </div>
                )}
              </div>
              <div className="ms-sm-auto csdhvbsdk">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 bg-color-e6">
        <div className="container-lg cl-custom">
          <div className="secdkhgbkjrs mb-2">Importance of a good Mentor ship </div>
          <div className="row">

            <div className="col-sm-6 col-12 mb-5">
              <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</div>
            </div>

          </div>
        </div>
      </section>

    </>
  );
};
