import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";


const RightSvg = () => {
    return(
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
    </svg>
    )
}


export default function PageSitemap() {

    const SitemapLink = [ 
        {
            Link: "/",
            LinkName: "Home",
        },
        {
            Link: "/about",
            LinkName: "About",
        },
        {
            Link: "/index2",
            LinkName: "Our Mentors",
        },
        {
            Link: "/blogs",
            LinkName: "Blogs",
        },
        {
            Link: "/privacy-policy",
            LinkName: "Privacy Policy",
        },
        {
            Link: "/terms-conditions",
            LinkName: "Terms & Conditions",
        },
        {
            Link: "/contact",
            LinkName: "Get In Touch",
        },
    ]

  return (
    <div>
      <Helmet>
        <title>Sitemap | My Mentor</title>
      </Helmet>


      <section>
        <div className="breadcrumb-outer">
          <div className="container-lg cl-custom">
            <div className="breadcrumb-bx">
                <Link className="breadcrumb-link" to="/">Home<i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                <Link className="breadcrumb-link breadcrumb-active">Sitemap</Link>
            </div>
          </div> 
        </div>
      </section>

      <section className="pt-4 pb-3">
          <div className="container-lg cl-custom">
      
                <div className="common-heading-bx text-center mt-4 pb-2">
                    <div className="common-heading">Sitemap</div>
                </div>

                <span className="sitemap-heding mt-4">Usefull Links</span>
                <ul className="sitemap-ul">
                    {SitemapLink.map((item, index) => ( 
                    <li key={index}>
                        <Link className="sitemap-link" to={item.Link}>
                            {RightSvg()}{item.LinkName}
                        </Link>
                    </li>
                    ))}
                </ul>

          </div>
      </section>

  
    </div>
  );
};
