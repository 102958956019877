import React from 'react';

// Slider Code
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

// Images
import clientSay1 from '../../img/clientSay1.png';
import clientSay2 from '../../img/clientSay2.png';
import clientSay3 from '../../img/clientSay3.png';
import clientSay4 from '../../img/clientSay4.png';
import clientSay5 from '../../img/clientSay5.png';
import clientSay6 from '../../img/clientSay6.png';


const start = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
        </svg>
    )
}

export default function Testimonials() {
    const testimonialslickslider = {
        arrows:false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        swipeToSlide: true,
        speed: 500,
        autoplaySpeed: 3000,
    };
  return (
    <>
        <Slider className="testimonial-slider-bx mt-5" {...testimonialslickslider}>

            <div className="slider-item-outer">
                <div className="testimonial-card-item">
                    {/* <div className="tci-img">
                        <img src={demo1} alt="" />
                    </div> */}
                    
                    <div className="tci-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Interdum posuere lorem ipsum dolor sit amet consectetur adipiscing elit. Platea dictumst quisque sagittis purus sit.</div>
                    <div className="tci-name">Rohan Parmar</div>
                    <div className="tci-star-bx">
                        <span className="active">{start()}</span>
                        <span className="active">{start()}</span>
                        <span className="active">{start()}</span>
                        <span className="active">{start()}</span>
                        <span className="active">{start()}</span>
                    </div>
                </div>
            </div>

            <div className="slider-item-outer">
                <div className="testimonial-card-item">
                    {/* <div className="tci-img">
                        <img src={demo1} alt="" />
                    </div> */}
                    
                    <div className="tci-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Interdum posuere lorem ipsum dolor sit amet consectetur adipiscing elit. Platea dictumst quisque sagittis purus sit.</div>
                    <div className="tci-name">Rahul Patel</div>
                    <div className="tci-star-bx">
                        <span className="active">{start()}</span>
                        <span className="active">{start()}</span>
                        <span className="active">{start()}</span>
                        <span className="active">{start()}</span>
                        <span className="active">{start()}</span>
                    </div>
                </div>
            </div>

        
        </Slider>
        <img className="d-none tsb-img tsb1" src={clientSay1} alt="" />
        <img className="d-none tsb-img tsb2" src={clientSay2} alt="" />
        <img className="d-none tsb-img tsb3" src={clientSay3} alt="" />
        <img className="d-none tsb-img tsb4" src={clientSay4} alt="" />
        <img className="d-none tsb-img tsb5" src={clientSay5} alt="" />
        <img className="d-none tsb-img tsb6" src={clientSay6} alt="" />
    </>
  )
}
