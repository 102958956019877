import React from 'react';
import { Link, NavLink } from "react-router-dom";

// Images Common File
import * as Img from '../Img';

// Css File
import '../../css/footer.css';

// Design Files
import Socialicon from '../Socialicon';

const RightSvg = () => {
    return(
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
    </svg>
    )
}

const heartSvg = () => {
    return(
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
    </svg>
    )
}

const heartfillSvg = () => {
    return(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <path d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
    </svg>
    )
}

export default function Footer(props) {
  return (
    <>
        <footer className={`pt-5 ${props.className}`}>
            <div className="container-lg cl-custome">
                <div className="row">

                    <div className="col-lg-4 mb-lg-0 text-center text-lg-start mb-4">
                        <Link className="footer-logo" to="/">
                            <img src={Img.logo_color} alt="" />
                        </Link>
                        <div className="footer-sub-heading">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                    </div>

                    <div className="col-lg-5 col-md-6 mb-mb-0 mb-4">
                        <div className="footer-heading">Explore</div>
                        <div className="footer-links-bx flb-flex-row">
                            <NavLink to="/" className="footer-link">{RightSvg()}Home</NavLink>
                            <NavLink to="/about" className="footer-link">{RightSvg()}About</NavLink>
                            <NavLink to="/index2" className="footer-link">{RightSvg()}Our Mentors</NavLink>
                            <NavLink to="/blogs" className="footer-link">{RightSvg()}Blogs</NavLink>
                            <NavLink to="/privacy-policy" className="footer-link">{RightSvg()}Privacy Policy</NavLink>
                            <NavLink to="/terms-conditions" className="footer-link">{RightSvg()}Terms & Conditions</NavLink>
                            <NavLink to="/contact" className="footer-link">{RightSvg()}Get In Touch</NavLink>
                            <NavLink to="/sitemap" className="footer-link">{RightSvg()}Sitemap</NavLink>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="footer-heading">Contact Info</div>
                        <div className="footer-contact">
                            <a href="tel:+918128181082" className="footer-contact-item">
                                <svg className="fci-img" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                                </svg>
                                <div className="fci-data">+91 812 818 1082</div>
                            </a>
                            <a href="mailto:info@mymentor.com" className="footer-contact-item">
                                <svg className="fci-img" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                                </svg>
                                <div className="fci-data">info@mymentor.com</div>
                            </a>
                        </div>

                        <div className="footer-heading text-center text-md-start mt-3">Social Media</div>
                        <Socialicon className="social-icon justify-content-md-start" />
                    </div>

                </div>
                <div className="footer-bottom">
                    <div className="copyright">© 2024 My Mentor. All Rights Reserved.</div>
                    <div className="mede-with">Made with
                        <span className="icons-box-made">
                            {heartSvg()}
                            {heartfillSvg()}
                        </span>
                        by: <a href="https://pmcommu.com/" target="_blank" rel="noreferrer">PM Communications</a>
                    </div>
                </div>
            </div>
        </footer>
    </>
  )
}
