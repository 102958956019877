import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { InputField, SelectField } from '../../configue/FormValidator';
import { API_HOST, live_token } from '../../configue/confifigue'
import { useAuth, getLoggedInUserData } from '../../contexts/AuthContext';
import FlashMessage from '../../components/FlashMessage';
import axios from 'axios';
// Images Common File
import * as Img from '../../components/Img';

// Css File
import '../../css/signinup.css'


const hidepassSvg = () => {
    return (
        <svg className="gri_password" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
        </svg>
    )
}

const viewpassSvg = () => {
    return (
        <svg className="gri_password" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
    )
}

export default function PageSignUp() {

    const [userLoggedIn, setUserLoggedIn] = useState(false)
    const { dispatch, state } = useAuth();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();


    const [gender, setGender] = useState('')
    const [firstName, setFirstname] = useState('');
    const [lastName, setLastname] = useState('');
    const [phoneNumber, setPhonenumber] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
 

    const genderRef = useRef();
    const firstnameRef = useRef();
    const lastnameRef = useRef();
    const phoneNumberRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();

    const [loginSuccsess,setLoginSuccsess] = useState(false);
const [loginError,setLoginError] = useState(false);
const [emailError,setEmailError] = useState(false);
const [phoneNumberError,setPhoneNumberError] = useState(false);


    useEffect(() => {
        const loggedInUser = getLoggedInUserData();
        const isUserLoggedIn = !!loggedInUser;

        if (isUserLoggedIn) {
            setUserLoggedIn(true);
            navigate('/')
        }
    }, [state.userData]);


    const handleGenderChange = (e) => {
        setGender(e.target.value);
    };


    const handleFirstNameChange = (e) => {
        setFirstname(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastname(e.target.value);
    };

    const handlePhoneNumberChange = (e) => {
        setPhonenumber(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };



    const [showPasswords, setShowPasswords] = useState([false, false, false]);

    const togglePasswordVisibility = (index) => {
        setShowPasswords((prevShowPasswords) => {
            const updatedShowPasswords = [...prevShowPasswords];
            updatedShowPasswords[index] = !updatedShowPasswords[index];
            return updatedShowPasswords;
        });
    };




    const handleValidation = async () => {
        setLoginSuccsess(false);
        setLoginError(false);
        setEmailError(false);
        setPhoneNumberError(false);

        genderRef.current.validate();
        firstnameRef.current.validate();
        lastnameRef.current.validate();
        phoneNumberRef.current.validate();
        emailRef.current.validate();
        passwordRef.current.validate();
        confirmPasswordRef.current.validate();

        if (
            genderRef.current.getError() ||
            firstnameRef.current.getError() ||
            lastnameRef.current.getError() ||
            phoneNumberRef.current.getError() ||
            emailRef.current.getError() ||
            passwordRef.current.getError() ||
            confirmPasswordRef.current.getError()
        ) {
            return;
        }

        try {
            const token = live_token

            const response = await axios.post(`${API_HOST}/user/`, {
                gender:gender,
                first_name: firstName,
                last_name: lastName,
                phone_number: phoneNumber,
                email: email,
                password: password,
                confirm_password: confirmPassword,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            console.log(response.data);
            if (response.data) {
                setLoginSuccsess(true);
                localStorage.setItem('userData', JSON.stringify(response.data));
                dispatch({ type: 'LOGIN', payload: response.data });
                setIsLoggedIn(true);
                setTimeout(() => {
                    navigate('/');
                }, 3000);
            }else{
                setLoginError(true);
             }
        } catch (error) {
            if (error.response.data) {
              const validationErrors = error.response.data;
          
              if (validationErrors['Phone Number']) {
                setPhoneNumberError(true);
              }
          
              if (validationErrors.email) {
                setEmailError(true);
              }
          
            }
          }
    };


    const genderOptions = [
        { value: '', label: 'Select Gender' },
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
    ];

    return (
        <>
            <Helmet>
                <title>Sign Up | My Mentor</title>
            </Helmet>

            <div className="login-page-back-effect"></div>
            <div className="sign-in-up-bx">

                <div className='signinup-screen mw768ss active' id="registration-screen">

                    <div className='signinup-header'>
                        <Link to="/signin" className="back-btn" title="Back to Login">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" color="#F2F1F3" width="20px"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path></svg>
                        </Link>
                        <div>
                            <div className="screen-hed">Sign Up</div>
                            <div className="screen-pera">Access to your dashboard</div>
                        </div>
                    </div>


                    <form>

                        <div className="row">

                            <div className="col-sm-4 col-6">
                                <div className="signinup-group sgri">
                                    <div className="group__label">Gender</div>
                                    <SelectField
                                        name="Gender"
                                        required
                                        options={genderOptions}
                                        value={gender}
                                        onChange={handleGenderChange}
                                        ref={genderRef}
                                    />
                                    <span className="down-icon"></span>
                                </div>
                            </div>

                            <div className="col-12"></div>

                            <div className="col-6">
                                <div className="signinup-group">
                                    <div className="group__label">First Name</div>
                                    <InputField
                                        name="Firstname"
                                        type="text"
                                        placeholder="Enter first name"
                                        required={true}
                                        value={firstName}
                                        onChange={handleFirstNameChange}
                                        ref={firstnameRef}
                                    />
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="signinup-group">
                                    <div className="group__label">Last Name</div>
                                    <InputField
                                        name="Lastname"
                                        type="text"
                                        placeholder="Enter last name"
                                        required={true}
                                        value={lastName}
                                        onChange={handleLastNameChange}
                                        ref={lastnameRef}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="signinup-group">
                                    <div className="group__label">Phone Number</div>
                                    <InputField
                                        name="Phonenumber"
                                        type="number"
                                        placeholder="Enter phone number"
                                        required={true}
                                        value={phoneNumber}
                                        onChange={handlePhoneNumberChange}
                                        ref={phoneNumberRef}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="signinup-group">
                                    <div className="group__label">Your email</div>
                                    <InputField
                                        name="Email"
                                        type="email"
                                        placeholder="you@example.com"
                                        required={true}
                                        value={email}
                                        onChange={handleEmailChange}
                                        ref={emailRef}
                                    />
                                </div>
                            </div>

                            {[0, 1].map((index) => (
                                <div className={`col-sm-6 ${index === 0 ? '' : ''}`} key={index}>
                                    <div className="signinup-group sgri">
                                        <div className="group__label">{index === 0 ? "Password" : "Confirm password"} </div>
                                        <InputField
                                            name={index === 0 ? "Password" : "Confirm Password"}
                                            type={showPasswords[index] ? 'text' : 'password'}
                                            placeholder={`Enter ${index === 0 ? "" : "confirm"} password`}
                                            required={true}
                                            value={index === 0 ? password : confirmPassword}
                                            onChange={index === 0 ? handlePasswordChange : handleConfirmPasswordChange}
                                            ref={index === 0 ? passwordRef : confirmPasswordRef}
                                        />
                                        <span
                                            className={`group_right_icon ${showPasswords[index] ? "active" : ""}`}
                                            onClick={() => togglePasswordVisibility(index)}
                                        >
                                            {showPasswords[index] ? viewpassSvg() : hidepassSvg()}
                                        </span>
                                    </div>
                                </div>
                            ))}


                        </div>

                        <button type="button" onClick={handleValidation} className="login-submit-btn">Continue</button>

                        {/* <div className="lsb-loader-btn">
                            <img src={Img.loading} alt="" />Please wait ....
                        </div> */}

                        <div className="signinuptogleblink">
                            Already have an account?
                            <Link to='/signin' className="siulink">Login</Link>
                        </div>
                        {loginSuccsess && <FlashMessage type="success" isvisible={true} message="Register successfull" />}
                        {loginError && <FlashMessage type="error" isvisible={true} message="Email or password did not match" />}
                        {emailError && <FlashMessage type="error" isvisible={true} message="Email already exist" />}
                        {phoneNumberError && <FlashMessage type="error" isvisible={true} message="Phone number already exist" />}
                    </form>

                </div>

            </div>
        </>
    );
};