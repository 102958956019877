import React from 'react';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

// Images
import demo1 from '../../img/demo/demo(1).jpg';
import demo2 from '../../img/demo/demo(2).jpg';
import demo3 from '../../img/demo/demo(3).png';
import demo4 from '../../img/demo/demo(4).png';
import demo5 from '../../img/demo/demo(5).png';

export default function PageBlogs() {
  return (
    <>

    <Helmet>
      <title>Blogs | My Mentor</title>
    </Helmet>

    <section>
      <div className="breadcrumb-outer">
        <div className="container-lg cl-custom">
          <div className="breadcrumb-bx">
              <Link className="breadcrumb-link" to="/">Home<i className="fa fa-angle-right" aria-hidden="true"></i></Link>
              <Link className="breadcrumb-link breadcrumb-active">Blogs</Link>
          </div>
        </div> 
      </div>
    </section>

    <section className="pt-4 pb-3">
        <div className="container-lg cl-custom">
            <div className="common-heading-bx text-center mt-4 pb-2">
                <div className="common-top-heading">Get the latest News</div>
                <div className="common-heading">News And Blog</div>
                <div className="common-sub-heading">Do you want to move on next step? Choose your most popular <br/> leaning mentors, it will help you to achieve your professional goals.</div>
            </div>
            <div className="row mt-4">

                <div className="col-lg-3 col-md-3 col-sm-6 my-3">
                    <div className="news-card-item">
                        <div className="nci-img">
                            <img src={demo1} alt="" />
                        </div>
                        <div className="nci-heading">Investment Insights: Navigating the Financial Markets</div>
                        <div className="nci-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Interdum posuere lorem ipsum dolor sit amet consectetur adipiscing elit. Platea dictumst quisque sagittis purus sit.</div>
                        <div className="nci-date">Posted November 15, 2019</div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6 my-3">
                    <div className="news-card-item">
                        <div className="nci-img">
                            <img src={demo2} alt="" />
                        </div>
                        <div className="nci-heading">Investment Insights: Navigating the Financial Markets</div>
                        <div className="nci-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Interdum posuere lorem ipsum dolor sit amet consectetur adipiscing elit. Platea dictumst quisque sagittis purus sit.</div>
                        <div className="nci-date">Posted November 15, 2019</div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6 my-3">
                    <div className="news-card-item">
                        <div className="nci-img">
                            <img src={demo3} alt="" />
                        </div>
                        <div className="nci-heading">Investment Insights: Navigating the Financial Markets</div>
                        <div className="nci-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Interdum posuere lorem ipsum dolor sit amet consectetur adipiscing elit. Platea dictumst quisque sagittis purus sit.</div>
                        <div className="nci-date">Posted November 15, 2019</div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6 my-3">
                    <div className="news-card-item">
                        <div className="nci-img">
                            <img src={demo4} alt="" />
                        </div>
                        <div className="nci-heading">Investment Insights: Navigating the Financial Markets</div>
                        <div className="nci-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Interdum posuere lorem ipsum dolor sit amet consectetur adipiscing elit. Platea dictumst quisque sagittis purus sit.</div>
                        <div className="nci-date">Posted November 15, 2019</div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6 my-3">
                    <div className="news-card-item">
                        <div className="nci-img">
                            <img src={demo5} alt="" />
                        </div>
                        <div className="nci-heading">Investment Insights: Navigating the Financial Markets</div>
                        <div className="nci-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Interdum posuere lorem ipsum dolor sit amet consectetur adipiscing elit. Platea dictumst quisque sagittis purus sit.</div>
                        <div className="nci-date">Posted November 15, 2019</div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6 my-3">
                    <div className="news-card-item">
                        <div className="nci-img">
                            <img src={demo1} alt="" />
                        </div>
                        <div className="nci-heading">Investment Insights: Navigating the Financial Markets</div>
                        <div className="nci-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Interdum posuere lorem ipsum dolor sit amet consectetur adipiscing elit. Platea dictumst quisque sagittis purus sit.</div>
                        <div className="nci-date">Posted November 15, 2019</div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6 my-3">
                    <div className="news-card-item">
                        <div className="nci-img">
                            <img src={demo2} alt="" />
                        </div>
                        <div className="nci-heading">Investment Insights: Navigating the Financial Markets</div>
                        <div className="nci-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Interdum posuere lorem ipsum dolor sit amet consectetur adipiscing elit. Platea dictumst quisque sagittis purus sit.</div>
                        <div className="nci-date">Posted November 15, 2019</div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6 my-3">
                    <div className="news-card-item">
                        <div className="nci-img">
                            <img src={demo3} alt="" />
                        </div>
                        <div className="nci-heading">Investment Insights: Navigating the Financial Markets</div>
                        <div className="nci-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Interdum posuere lorem ipsum dolor sit amet consectetur adipiscing elit. Platea dictumst quisque sagittis purus sit.</div>
                        <div className="nci-date">Posted November 15, 2019</div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6 my-3">
                    <div className="news-card-item">
                        <div className="nci-img">
                            <img src={demo4} alt="" />
                        </div>
                        <div className="nci-heading">Investment Insights: Navigating the Financial Markets</div>
                        <div className="nci-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Interdum posuere lorem ipsum dolor sit amet consectetur adipiscing elit. Platea dictumst quisque sagittis purus sit.</div>
                        <div className="nci-date">Posted November 15, 2019</div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6 my-3">
                    <div className="news-card-item">
                        <div className="nci-img">
                            <img src={demo5} alt="" />
                        </div>
                        <div className="nci-heading">Investment Insights: Navigating the Financial Markets</div>
                        <div className="nci-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Interdum posuere lorem ipsum dolor sit amet consectetur adipiscing elit. Platea dictumst quisque sagittis purus sit.</div>
                        <div className="nci-date">Posted November 15, 2019</div>
                    </div>
                </div>

            </div>
        </div>
    </section>

    <div className="pt-5"></div>
    </>
  )
}
