import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import { API_HOST, live_token, imageUrl } from '../../configue/confifigue'
import axios from 'axios';

// Images
import demo1 from '../../img/demo/avatar1.png';
import demo2 from '../../img/demo/avatar2.png';
import demo3 from '../../img/demo/avatar3.png';
import demo4 from '../../img/demo/avatar4.png';

// Design Files
import EmptyBox from '../../components/EmptyBox';


const start = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
        </svg>
    )
}

const location = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
        </svg>
    )
}

const date = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
        </svg>

    )
}

const eye = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
        </svg>
    )
}

export default function PageSearch() {

    const [mentors, setMentors] = useState([]);
    const { searchText } = useParams();
    const [emptyData, setEmptyData] = useState(false);
    const [searchResult, setSearchResult] = useState(0);

    const navigate = useNavigate();
    const [selectedFilters, setSelectedFilters] = useState([]);


    const getRandomDemoImage = () => {
        const demoImages = [demo1, demo2, demo3, demo4];
        const randomIndex = Math.floor(Math.random() * demoImages.length);
        return demoImages[randomIndex];
    };



    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = live_token;
                let apiUrl = `${API_HOST}/mentors/`;

                if (searchText) {
                    apiUrl = `${API_HOST}/search/${searchText}`;
                }

                const response = await axios.get(apiUrl, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                setSearchResult(response.data.length)
                if (response.data.length <= 0) {
                    setEmptyData(true);
                } else {
                    setEmptyData(false);
                    setMentors(response.data || []);
                }

            } catch (error) {
                console.error('API request error:', error);
            }
        };

        fetchData();

    }, [searchText]);






    const [isFilter, setIsFilter] = useState(false);
    const [isSort, setIsSort] = useState(false);

    const toggleFilter = () => {
        setIsFilter(!isFilter)
    }

    const handleSort = () => {
        setIsSort(true);
    };

    const closeSort = () => {
        setIsSort(false);
    };

    const closeFilter = () => {
        setIsFilter(false);
    };


    const appendBaseUrl = (path) => {
        const baseUrl = imageUrl;
        return path.includes(baseUrl) ? path : baseUrl + path;
    };

    const handleResetAll = () => {
        navigate('/search');
    };

    const handleCheckboxChange = async (filterData) => {
        try {
            const token = live_token;
            let apiUrl = `${API_HOST}/filters/`;
    
            const updatedFilters = selectedFilters.includes(filterData)
                ? selectedFilters.filter((f) => f !== filterData)
                : [...selectedFilters, filterData];
    
            setSelectedFilters(updatedFilters);
    
            apiUrl += `?filters=${updatedFilters.join(',')}`;
    
            const response = await axios.get(apiUrl, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
           
            setSearchResult(response.data.length)
            if (response.data.length <= 0) {
                setEmptyData(true);
                setMentors([])
            } else {
                setEmptyData(false);
                setMentors(response.data || []);    
            }
        } catch (error) {
            console.error('API request error:', error);
        }
    };
    







    return (
        <>
            <Helmet>
                <title>Search | My Mentor</title>
            </Helmet>

            <section className="search-main-section maxwidth1920">

                {/* Search Page Right Section or content Section Start */}
                <div className={`spage-right ${isFilter ? 'active' : ''} `}>

                    {/* --- Search Page Top Bx Start --- */}
                    <div className="spage-r-top">
                        <div className="spage-r-lable">Mentors In Florida<span className="showing-result">"{searchResult} matches found"</span></div>
                        <div className="spage-r-btn">
                            <div className={`sortfilterbtn ${isSort ? 'active' : ''}`} onClick={handleSort}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5" />
                                </svg>
                                Sort By <span>Popular</span>
                            </div>
                            <div className={`sortfilterbtn ${isFilter ? 'active' : ''}`} onClick={toggleFilter}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                                </svg>
                                {isFilter ? ("Hide") : ("Show")} Filters
                            </div>
                        </div>
                    </div>
                    {/* --- Search Page Top Bx End --- */}

                    {/* --- Search Content Bx Start --- */}
                    <div className="spage-r-list">

                        <div className="row mx-0">

                            {/* <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="search-card-item">
                                    <Link to="/" className="sci-top-bx">
                                        <img className="sci-img" src={demo1} alt="" />
                                        <div className="sci-data-bx">
                                            <div className="sci-name">Steve Arlin</div>
                                            <div className="sci-speciality">English</div>
                                            <div className="sci-location">{location()}Washington Square Park, Washington Square, New York, NY, USA</div>
                                        </div>
                                        <div className="sci-start-rating">{start()}4.9</div>
                                    </Link>
                                    <div className="sci-btn-bx">
                                        <Link to="/checkout" className="sci-btn-link">{date()}Schedule</Link>
                                        <Link to="/detail" className="sci-btn-link">{eye()}View Details</Link>
                                    </div>
                                </div>
                            </div> */}


                            {mentors.map((mentor) => (
                                <div className="col-lg-3 col-md-4 col-sm-6" key={mentor.id}>
                                    <div className="search-card-item">
                                        <Link to={mentor.id} className="sci-top-bx">
                                            <img
                                                className="sci-img"
                                                src={mentor.profile_pic ? appendBaseUrl(mentor.profile_pic) : getRandomDemoImage()}
                                                alt=""
                                            />
                                            <div className="sci-data-bx">
                                                <div className="sci-name">{mentor.first_name}  {mentor.last_name}</div>
                                                <div className="sci-speciality">English</div>
                                                <div className="sci-location">{location()}Washington Square Park, Washington Square, New York, NY, USA</div>
                                            </div>
                                            <div className="sci-start-rating">{start()}{mentor.rating}</div>
                                        </Link>
                                        <div className="sci-btn-bx">
                                            <Link to={`/checkout/${mentor.id}`} key={mentor.id} className="sci-btn-link">{date()}Schedule</Link>
                                            <Link to={`/detail/${mentor.id}`} key={mentor.id} className="sci-btn-link">{eye()}View Details</Link>
                                        </div>
                                    </div>
                                </div>
                            ))}



                        </div>

                        {emptyData && <EmptyBox eh="No results 🥺" esh="Try adjusting your filters to get more results." />}

                    </div>
                    {/* --- Search Content Bx End --- */}

                </div>
                {/* Search Page Right Section or content Section Start */}

                {/* Search Page Left Section or Filter Section */}
                <aside className={`spage-left common-box-shadow ${isFilter ? 'active' : ''} `}>
                    <div className="sp-left-header">
                        <div className="slp-lable">Filters</div>
                        <span className="all-reset-btn active" onClick={handleResetAll} id="reset-all-btn">Clear all</span>
                        <span className="close-icon" onClick={closeFilter}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                            </svg>
                        </span>
                    </div>
                    <div id="accordion" className="serach-filter-accordion"> {/* data-bs-parent="#accordion" */}

                        <div className="acc-item">
                            <div className="acc-heding" data-bs-toggle="collapse" data-bs-target="#collapseGender" aria-expanded="true" aria-controls="collapseGender">Gender<span className="down-icon"><i className="fa fa-angle-down"></i></span></div>

                            <div id="collapseGender" className="collapse show" aria-labelledby="headingGender">
                                <div className="acc-detail">
                                    <div className="checkbox-group full-width-cb check-square">
                                        <input
                                            type="checkbox"
                                            id="gr-cb1"
                                            checked={selectedFilters.includes('Male')}
                                            onChange={() => handleCheckboxChange('Male')}
                                        />
                                        <label htmlFor="gr-cb1"><span className="check-icon"></span>Male<span className="num-tag">50</span> </label>
                                        <input
                                            type="checkbox"
                                            id="gr-cb2"
                                            checked={selectedFilters.includes('Female')}
                                            onChange={() => handleCheckboxChange('Female')}
                                        />
                                        <label htmlFor="gr-cb2"><span className="check-icon"></span>Female<span className="num-tag">20</span></label>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="acc-item">
                            <div className="acc-heding" data-bs-toggle="collapse" data-bs-target="#collapseCourses" aria-expanded="true" aria-controls="collapseCourses">Select Courses<span className="down-icon"><i className="fa fa-angle-down"></i></span></div>

                            <div id="collapseCourses" className="collapse show" aria-labelledby="headingCourses">
                                <div className="acc-detail">
                                    <div className="checkbox-group full-width-cb check-square">
                                        <input
                                            type="checkbox"
                                            id="courses-cb1"
                                            checked={selectedFilters.includes('course one')}
                                            onChange={() => handleCheckboxChange('course one')}
                                        />
                                        <label htmlFor="courses-cb1"><span className="check-icon"></span>course one<span className="num-tag">02</span></label>

                                        <input
                                            type="checkbox"
                                            id="courses-cb2"
                                            checked={selectedFilters.includes('course two')}
                                            onChange={() => handleCheckboxChange('course two')}
                                        />
                                        <label htmlFor="courses-cb2"><span className="check-icon"></span>course two<span className="num-tag">02</span></label>

                                        <input
                                            type="checkbox"
                                            id="courses-cb3"
                                            checked={selectedFilters.includes('course three')}
                                            onChange={() => handleCheckboxChange('course three')}
                                        />
                                        <label htmlFor="courses-cb3"><span className="check-icon"></span>course three<span className="num-tag">02</span></label>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="acc-item">
                            <div className="acc-heding" data-bs-toggle="collapse" data-bs-target="#collapseIndustries" aria-expanded="true" aria-controls="collapseIndustries">Industries<span className="down-icon"><i className="fa fa-angle-down"></i></span></div>

                            <div id="collapseIndustries" className="collapse show" aria-labelledby="headingIndustries">
                                <div className="acc-detail">
                                    <div className="checkbox-group full-width-cb check-square">
                                        <input
                                            type="checkbox"
                                            id="industries-cb1"
                                            checked={selectedFilters.includes('IT')}
                                            onChange={() => handleCheckboxChange('IT')}
                                        />
                                        <label htmlFor="industries-cb1"><span className="check-icon"></span>IT</label>

                                        <input
                                            type="checkbox"
                                            id="industries-cb2"
                                            checked={selectedFilters.includes('Finance')}
                                            onChange={() => handleCheckboxChange('Finance')}
                                        />
                                        <label htmlFor="industries-cb2"><span className="check-icon"></span>Finance</label>

                                        <input
                                            type="checkbox"
                                            id="industries-cb3"
                                            checked={selectedFilters.includes('Healthcare')}
                                            onChange={() => handleCheckboxChange('Healthcare')}
                                        />
                                        <label htmlFor="industries-cb3"><span className="check-icon"></span>Healthcare</label>

                                        
                                        <input
                                            type="checkbox"
                                            id="industries-cb4"
                                            checked={selectedFilters.includes('Education')}
                                            onChange={() => handleCheckboxChange('Education')}
                                        />
                                        <label htmlFor="industries-cb4"><span className="check-icon"></span>Education</label>

                                        <input
                                            type="checkbox"
                                            id="industries-cb5"
                                            checked={selectedFilters.includes('Manufacturing')}
                                            onChange={() => handleCheckboxChange('Manufacturing')}
                                        />
                                        <label htmlFor="industries-cb5"><span className="check-icon"></span>Manufacturing</label>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="sortfilter-footer">
                        <div className="common-btn-item cbi-fill" onClick={closeFilter}><span>Apply Filters</span></div>
                    </div>
                </aside>
                {/* Search Page Left Section or Filter Section */}

            </section>

            {/* --- Sort By Filter Popup --- */}
            <div className={`sort-filter-popup ${isSort ? 'active' : ''} `}>
                <div className="sort-filter-inner">
                    <span className="close-icon" onClick={closeSort}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="38" d="M368 368L144 144M368 144L144 368" /></svg>
                    </span>
                    <div className="sort-popup-lable">Sort By</div>
                    <div className="checkbox-group full-width-cb cg-align-center">
                        <input type="radio" id="sfp-cb1" name="sorffilters" />
                        <label htmlFor="sfp-cb1"><span className="check-icon"></span>Recommended</label>

                        <input type="radio" id="sfp-cb2" name="sorffilters" />
                        <label htmlFor="sfp-cb2"><span className="check-icon"></span>Price: high - low</label>

                        <input type="radio" id="sfp-cb3" name="sorffilters" />
                        <label htmlFor="sfp-cb3"><span className="check-icon"></span>Price: low - high</label>

                        <input type="radio" id="sfp-cb4" name="sorffilters" />
                        <label htmlFor="sfp-cb4"><span className="check-icon"></span>Number of reviews</label>

                        <input type="radio" id="sfp-cb5" name="sorffilters" />
                        <label htmlFor="sfp-cb5"><span className="check-icon"></span>Newest</label>

                        <input type="radio" id="sfp-cb6" name="sorffilters" />
                        <label htmlFor="sfp-cb6"><span className="check-icon"></span>Alphabetical</label>
                    </div>
                    <div className="sortfilter-footer">
                        <div className="common-btn-item cbi-fill" onClick={closeSort}><span>Apply Sorting</span></div>
                    </div>
                </div>

            </div>

            {/* --- Serach Page overlay div --- */}
            <div className={`serach-overlay ${isFilter ? 'filter-active' : ''} `} onClick={closeFilter}></div>
            <div className={`serach-overlay ${isSort ? 'active' : ''} `} onClick={closeSort}></div>
        </>
    )
}
