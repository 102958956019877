import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { API_HOST, live_token, imageUrl } from '../../configue/confifigue'
import { useAuth, getLoggedInUserData } from '../../contexts/AuthContext';
import axios from 'axios';
import Commonpopup from '../../components/Commonpopup';
import Commonpopup3 from '../../components/Commonpopup3';
// Images Common File
import * as Img from '../../components/Img';

// Design File
import Commonlayout from "./Commonlayout";
import EmptyBox from '../../components/EmptyBox';


export default function PageAppointment() {

    const [userLoggedIn, setUserLoggedIn] = useState(false)
    const { dispatch, state } = useAuth();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState('');
    const navigate = useNavigate();

    const [appointmentOptions, setAppointmentOptions] = useState([]);

    const fetchData = async () => {
        try {
            const API_ENDPOINT = `${API_HOST}/mentor-appointments/${user.id}`;
            const HEADERS = {
                'Authorization': `Bearer ${live_token}`,
                'Content-Type': 'application/json',
            };

            const response = await axios.get(API_ENDPOINT, {
                headers: HEADERS,
            });


            if (Array.isArray(response.data) && response.data.length > 0) {
                setAppointmentOptions(response.data);
            }

        } catch (error) {
            console.error('API request error:', error);
        }
    };


    const fetchDataTwo = async () => {
        try {
            const API_ENDPOINT = `${API_HOST}/mentee-appointments/${user.id}`;
            const HEADERS = {
                'Authorization': `Bearer ${live_token}`,
                'Content-Type': 'application/json',
            };

            const response = await axios.get(API_ENDPOINT, {
                headers: HEADERS,
            });

            if (Array.isArray(response.data) && response.data.length > 0) {
                setAppointmentOptions(response.data);
            }

        } catch (error) {
            console.error('API request error:', error);
        }
    };


    useEffect(() => {
        const loggedInUser = getLoggedInUserData();
        const isUserLoggedIn = !!loggedInUser;

        if (!isUserLoggedIn) {
            navigate('/signin')
        } else {
            setUser(loggedInUser);
            if (user.id && user.user_role === 'MENTOR') {
                fetchData();
            } else if (user.id && user.user_role === 'MENTEE') {
                fetchDataTwo();
            }
        }
    }, [state.userData, user.id, user.user_role]);



    return (
        <Commonlayout>

            <Helmet>
                <title>Appointment | My Mentor</title>
            </Helmet>

            {/* --- Profile Bx Start --- */}
            <div className="amib-inner-item">
                <div className="amib-992-width mt-4">
                    <div className="amib-i-header">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                        </svg>
                        Appointment
                    </div>


                    {appointmentOptions.length > 0 ? (
                        <div className="amib-item p-2">
                            {user.user_role === 'MENTOR' && (
                                <div className="appointment-list-bx">

                                    {appointmentOptions.map(appointment => (
                                        <div key={appointment.id} className="appointment-item">
                                            <div className="appointment-img-name">
                                                {/* Assuming you have an image URL in your appointment data */}
                                                <img className="appointment-img" src={appointment.mentee.profile_pic} />
                                                <div className="appointment-name-label">{appointment.mentee.first_name} {appointment.mentee.last_name}</div>
                                            </div>
                                            <div className="appointment-datetime">
                                                <div className="appointment-date">
                                                    {new Date(appointment.date).toLocaleDateString('en-US', {
                                                        day: 'numeric',
                                                        month: 'short',
                                                        year: 'numeric',
                                                    })}
                                                </div>
                                                <div className="appointment-time">
                                                    {new Date(`${appointment.date}T${appointment.time}`).toLocaleTimeString('en-US', {
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                    })}
                                                </div>
                                            </div>


                                            <div className="appointment-btns">
                                                <div data-bs-toggle="modal" data-bs-target="#Appointmentdetail" className="common-btn-item cbi-fill cb-small">
                                                    <span>
                                                        Details
                                                    </span>
                                                </div>
                                                <div data-bs-toggle="modal" data-bs-target="#SendLinkmodal" className="common-btn-item cbi-outline cb-small" cbdata="Send Link">
                                                    <span>
                                                        Send Link
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {user.user_role === 'MENTEE' && (
                                <div className="appointment-list-bx">

                                    {appointmentOptions.map(appointment => (
                                        <div key={appointment.id} className="appointment-item">
                                            <div className="appointment-img-name">
                                                {/* Assuming you have an image URL in your appointment data */}
                                                <img className="appointment-img" src={appointment.mentor.profile_pic} />
                                                <div className="appointment-name-label">{appointment.mentor.first_name} {appointment.mentor.last_name}</div>
                                            </div>
                                            <div className="appointment-datetime">
                                                <div className="appointment-date">
                                                    {new Date(appointment.date).toLocaleDateString('en-US', {
                                                        day: 'numeric',
                                                        month: 'short',
                                                        year: 'numeric',
                                                    })}
                                                </div>
                                                <div className="appointment-time">
                                                    {new Date(`${appointment.date}T${appointment.time}`).toLocaleTimeString('en-US', {
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                    })}
                                                </div>
                                            </div>


                                            <div className="appointment-btns">
                                                <div data-bs-toggle="modal" data-bs-target="#Appointmentdetail" className="common-btn-item cbi-fill cb-small">
                                                    <span>
                                                        Details
                                                    </span>
                                                </div>
                                                <div data-bs-toggle="modal" data-bs-target="#SendLinkmodal" className="common-btn-item cbi-outline cb-small" cbdata="Send Link">
                                                    <span>
                                                        Send Link
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                    ) : (
                        <EmptyBox esh="Find a mentor and book your appointment" />
                    )}
                </div>


            </div>
            {/* --- Profile Bx End --- */}

        </Commonlayout>
    );
};



{/* <div className="appointment-list-bx">

                            <div className="appointment-item">
                                <div className="appointment-img-name">
                                    <img className="appointment-img" src={Img.user} alt="" />
                                    <div className="appointment-name-lable">Fabrizio Romano</div>
                                </div>

                                <div className="appointment-datetime">
                                    <div className="appointment-date">20 Feb 2024</div>
                                    <div className="appointment-time">05:00 PM to 06:00 PM</div>
                                </div>

                                <div className="appointment-btns">
                                    <div data-bs-toggle="modal" data-bs-target="#Appointmentdetail" className="common-btn-item cbi-fill cb-small">
                                        <span>
                                            Details
                                        </span>
                                    </div>
                                    <div data-bs-toggle="modal" data-bs-target="#SendLinkmodal" className="common-btn-item cbi-outline cb-small" cbdata="Send Link">
                                        <span>
                                            Send Link
                                        </span>
                                    </div>
                                </div>
                            </div>


                        </div> */}