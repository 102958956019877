import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { MenuProvider } from '../components/Header/MenuContext';

//Header section
import ScrollToTop from "./ScrollToTop";
import Loader from "./Loader";
import Header from "../components/Header/Header";

// Pages
// import PageHome from "../containers/PageHome/PageHome";
import PageSearch from "../containers/PageSearch/PageSearch";
import PageDetail from "../containers/PageDetail/PageDetail";
import PageCheckout from "../containers/PageCheckout/PageCheckout";
import PageThankyou from "../containers/PageThankyou/PageThankyou";

// 
import PageNotFound from "../containers/PageNotFound/PageNotFound";
import PageAbout from "../containers/PageAbout/PageAbout";
import PageBlogs from "../containers/PageBlogs/PageBlogs";
import PageContact from "../containers/PageContact/PageContact";
import PagePrivacy from "../containers/PagreLagle/PagePrivacy";
import PageTermsConditions from "../containers/PagreLagle/PageTermsConditions";
import PageSitemap from "../containers/PagreLagle/PageSitemap";

//Accout Pages
import PageAccount from "../containers/PageAccount/PageAccount";
import PageSchedule from "../containers/PageAccount/PageSchedule";
import PageAppointment from "../containers/PageAccount/PageAppointment";
import PageNotifications from "../containers/PageAccount/PageNotifications";
import PagePassword from "../containers/PageAccount/PagePassword";

// Signinup Pages
import PageSignIn from "../containers/PageSignInUp/PageSignIn";
import PageSignUp from "../containers/PageSignInUp/PageSignUp";
import PageForgotPassword from "../containers/PageSignInUp/PageForgotPassword";
import PageResetPassword from "../containers/PageSignInUp/PageResetPassword";
import PageBecomeMentor from "../containers/PageSignInUp/PageBecomeMentor";

// 
import PageLookingMentor from "../containers/PageLookingMentor/PageLookingMentor";
import PageAreaInterest from "../containers/PageLookingMentor/PageAreaInterest";

// Footer section
import FlashMessage from "../components/FlashMessage";
import Footer from "../components/Footer/Footer";
import RequireAuth from '../RequireAuth';
import Commonpopup from "../components/Commonpopup";
import Commonpopup2 from "../components/Commonpopup2"
import Commonpopup3 from "../components/Commonpopup3";

import PageHome2 from "../containers/PageHome/PageHome2";
import PageHome3 from "../containers/PageHome/PageHome3";
import PageHome4 from "../containers/PageHome/PageHome4";
import PageHome5 from "../containers/PageHome/PageHome5";
import PageHome6 from "../containers/PageHome/PageHome6";
import PageHome7 from "../containers/PageHome/PageHome7";
import PageHome8 from "../containers/PageHome/PageHome8";

export const pages = [

  { path: "/index2", component: PageHome2 },
  { path: "/index3", component: PageHome3 },
  { path: "/index4", component: PageHome4 },
  { path: "/index5", component: PageHome5 },
  { path: "/index6", component: PageHome6 },
  { path: "/index7", component: PageHome7 },
  { path: "/index8", component: PageHome8 },
  
  // Home Page
  { path: "/", component: PageHome4 },
  { path: "/search", component: PageSearch },
  { path: "/search/:searchText", component: PageSearch },
  { path: "/detail", component: PageDetail },
  { path: "/detail/:id", component: PageDetail },
  { path: "/checkout", component: PageCheckout },
  { path: "/checkout/:id", component: PageCheckout },
  { path: "/thankyou", component: PageThankyou },

  { path: "/page-not-found", component: PageNotFound },
  { path: "/about", component: PageAbout },
  { path: "/blogs", component: PageBlogs },
  { path: "/contact", component: PageContact },
  { path: "/privacy-policy", component: PagePrivacy },
  { path: "/terms-conditions", component: PageTermsConditions },
  { path: "/sitemap", component: PageSitemap },

  { path: "/account", component: PageAccount },
  { path: "/schedule-timings", component: PageSchedule },
  { path: "/appointment", component: PageAppointment },
  { path: "/notifications", component: PageNotifications },
  { path: "/password", component: PagePassword },

  { path: "/signin", component: PageSignIn },
  { path: "/signup", component: PageSignUp },
  { path: "/forgotpassword", component: PageForgotPassword },
  { path: "/resetpassword", component: PageResetPassword },
  { path: "/become-mentor", component: PageBecomeMentor },

  // 
  { path: "/looking-mentor", component: PageLookingMentor },
  { path: "/area-of-interest", component: PageAreaInterest },

];

const MyRoutes = () => {

  const location = useLocation();

  const pathsToHideheader = ['/signin', '/signup', '/forgotpassword', '/resetpassword', '/become-mentor', '/looking-mentor', '/area-of-interest', '/page-not-found'];
  const pathsToHidefooter = ['/signin', '/signup', '/forgotpassword', '/resetpassword', '/become-mentor', '/looking-mentor', '/area-of-interest', '/page-not-found', '/search', '/account', '/schedule-timings', '/appointment', '/notifications', '/password'];
  const hideheader = pathsToHideheader.includes(location.pathname);
  const hidefooter = pathsToHidefooter.includes(location.pathname);

  // -----
  let HeaderclassName = "";

  if (location.pathname !== '/') {
    HeaderclassName = "active";
  }

  let FooterclassName = "";
  if (location.pathname === '/checkout') {
    FooterclassName = "checkout-bottom-padding";
  }
  // ----- //

  return (
    <>
      <ScrollToTop />
      <Loader />
      {!hideheader && <Header className={HeaderclassName} />}
      <main className="main-wrapper">
        <Routes>
          {pages.map(({ component: Component, path, allowedRoles }, index) => {

            if (allowedRoles && allowedRoles === true) {
              return (<Route key={index} element={<RequireAuth allowedRoles={[true]} />} >
                <Route element={<Component />} path={path} />
              </Route>);
            }
            else {
              return <Route key={index} element={<Component />} path={path} />;
            }

          })}
        </Routes>
      </main>
      
      <Commonpopup />
      <Commonpopup2/>
      <Commonpopup3/>
      <FlashMessage />
      {!hidefooter && <Footer className={FooterclassName} />}
    </>
  );
};

function MainRoutes() {
  return (
    <Router>
      <MenuProvider>
        <MyRoutes />
      </MenuProvider>
    </Router>
  );
}

export default MainRoutes;