import React from 'react';
import { Helmet } from "react-helmet-async";

// Images Common File
import Imgauthorized from '../../img/icons/notfound.png';
import EmptyBox from '../../components/EmptyBox';

export default function PageNotFound() {

    const authorizedbx = {
        height: '100vh', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "#f7f7f7"
    };

    const imageStyles = {
        width: "100%",
        maxWidth: "250px",
        filter: "invert(0)"
    };

  return (
    <>
    <Helmet>
        <title>Page Not Found | My Mentor</title>
    </Helmet>

    <section>
        <div style={authorizedbx}>
            <EmptyBox eistyle={imageStyles} ei={Imgauthorized} eh="Page is not found" esh="The resource requested could not be found on this server!" />
        </div>
    </section>
      
    </>
  )
}
