import React, { useState, useEffect } from 'react';
import { API_HOST, live_token, imageUrl } from '../configue/confifigue'
import axios from 'axios';
//Design Files
import Starrating from './StarRating';

// Images Common File
import * as Img from './Img';

function Commonpopup3() {
    const [rating, setRating] = useState(0);

    const handleRatingChange = (newRating) => {
        setRating(newRating);
    };



   

    return (
        <>
            {/* Send Link Popup */}
            <div className="modal fade zoom" id="SendLinkmodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content custom-content">
                        <div className="custom-modal-header">
                            <div className="cmh-lable">Send Video Call Link</div>

                            <span className="close-icon" data-bs-dismiss="modal" aria-label="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                                </svg>
                            </span>
                        </div>
                        <div className="custom-modal-data">
                            <form action="" className="px-3 pb-4">
                                <div className="row">

                                    <div className="col-12">
                                        <div className="signinup-group">
                                            <label className="group__label">Subject</label>
                                            <input type="text" placeholder="Enter subject here..." />
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="signinup-group">
                                            <label className="group__label">Video Call Link</label>
                                            <input type="text" placeholder="Enter Link here..." />
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="signinup-group">
                                            <label className="group__label">Message</label>
                                            <textarea placeholder="Enter message here..."></textarea>
                                        </div>
                                    </div>

                                </div>

                                <button type="button" className="common-submit-btn" disabled>Submit</button>
                                <button type="button" className="lsb-loader-btn">
                                    <img src={Img.loading} alt="Please wait" />Please wait ....
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Commonpopup3;