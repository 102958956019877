import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { InputField, SelectField } from '../../configue/FormValidator';
import { API_HOST, live_token, imageUrl } from '../../configue/confifigue'
import { useAuth, getLoggedInUserData } from '../../contexts/AuthContext';
import { useOption } from '../../contexts/OptionContext';
import axios from 'axios';

// Images Common File
import * as Img from '../../components/Img';

// Css File
import '../../css/signinup.css'

export default function PageAreaInterest() {

    const [userLoggedIn, setUserLoggedIn] = useState(false)
    const { dispatch, state } = useAuth();
    const [user, setUser] = useState('');
    const navigate = useNavigate();
    const { selectedOption, setOption } = useOption();
    const [error, setError] = useState(false);
    const [searchText, setSearchText] = useState(null);
 

    useEffect(() => {
        const loggedInUser = getLoggedInUserData();
        const isUserLoggedIn = !!loggedInUser;
        if (isUserLoggedIn) {
            setUserLoggedIn(true);
            setUser(loggedInUser)
        }
        else {
            navigate('/signin')
        }
    }, [state.userData]);
   
    useEffect(() => {
        if (!selectedOption) {
            navigate('/looking-mentor')
        }
    }, [selectedOption]);

    const handleRadioChange = (event) => {
        setError(false);
        setSearchText(event.target.value);
      };

    const handleCountinueClick = () => {
        if (!searchText) {
            setError(true);
            return;
        }else{
        const trimmedSearchText = searchText.trim();
        const searchUrl = trimmedSearchText ? `/search/${encodeURIComponent(trimmedSearchText)}` : '/search';
        navigate(searchUrl);
        }
      };




    return (
        <>

            <Helmet>
                <title>Area of Interest | My Mentor</title>
            </Helmet>

            <div className="login-page-back-effect"></div>
            <div className="sign-in-up-bx">

                <div className='signinup-screen active' id="login-screen">

                    <div className='signinup-header'>
                        <Link to="/looking-mentor" className="back-btn" title="Back to Home">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" color="#F2F1F3" width="20px"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path></svg>
                        </Link>
                        <div>
                            <div className="screen-hed"> Area <span> of </span>interest</div>
                            <div className="screen-pera">Select any of the details below</div>
                        </div>
                        {/* <img className="signinup-logo" src={Img.logo_color} alt="" /> */}
                    </div>

                    <form className="">

                        <div className="row">


                            <div className="col-12">
                                <div className="signinup-group">
                                    <div className="signinup-group-radio sgr-flex-column sgr-flex-row">

                                        <input type="radio" onChange={handleRadioChange} value="Information Technology" id="ai1" name="ai" hidden />
                                        <label htmlFor="ai1"><span className="sgr-icon"></span>Information Technology</label>

                                        <input type="radio" onChange={handleRadioChange} value="Finance" id="ai2" name="ai" hidden />
                                        <label htmlFor="ai2"><span className="sgr-icon"></span>Finance</label>

                                        <input type="radio" onChange={handleRadioChange} value="Healthcare" id="ai3" name="ai" hidden />
                                        <label htmlFor="ai3"><span className="sgr-icon"></span>Healthcare</label>

                                        <input type="radio" onChange={handleRadioChange} value="Education" id="ai4" name="ai" hidden />
                                        <label htmlFor="ai4"><span className="sgr-icon"></span>Education</label>

                                        <input type="radio" onChange={handleRadioChange} value="Manufacturing" id="ai5" name="ai" hidden />
                                        <label htmlFor="ai5"><span className="sgr-icon"></span>Manufacturing</label>

                                        <input type="radio" onChange={handleRadioChange} value="Business" id="ai7" name="ai" hidden />
                                        <label htmlFor="ai7"><span className="sgr-icon"></span>Business</label>

                                        <input type="radio" onChange={handleRadioChange} value="Startup" id="ai10" name="ai" hidden />
                                        <label htmlFor="ai10"><span className="sgr-icon"></span>Startup</label>
                                      
                                    </div>
                                    {error && <span className="form-error-msg">Please select atleast one option</span>}
                                </div>
                            </div>

                        </div>

                        <button type="button" onClick={handleCountinueClick} className="login-submit-btn">Continue</button>

                        {/* <div className="lsb-loader-btn">
                            <img src={Img.loading} alt="" />Please wait ....
                        </div> */}

                    </form>

                </div>

            </div>
        </>
    );
};