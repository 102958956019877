import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from './contexts/AuthContext'
import { HeaderProvider } from './contexts/HeaderProvider';
import {OptionProvider}  from './contexts/OptionContext';


// Css Links
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/font-awesome.min.css';
import './css/commonform.css';
import './css/flashmessage.css';
import './css/signinup.css';
import './css/style.css';
import './css/breadcrumb.css';
import './css/webloader.css';
import './css/mainsearch.css';
import './css/mentorscard.css';
import './css/socialicon.css';
import './css/testimonials.css';
import './css/newscard.css';
import './css/searchpage.css';
import './css/detail.css';
import './css/checkout.css';

// Scripts
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <HeaderProvider>
        <OptionProvider>
        <App />
        </OptionProvider>
      </HeaderProvider>
    </AuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
