import React from 'react';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

// Images Common File
// import * as Img from '../../components/Img';

import banner06 from '../../img/banner-06.png';


export default function PageHome() {

  return (
    <>
      <Helmet>
        <title>My Mentor</title>
      </Helmet>

      <section className="bg-color-e6">
        <div className="container-lg cl-custom">
          <div className="top-top-heading wg300">
            We help you shape your career by giving <br/>
            the mentor ship by industry professionals
          </div>
        </div>
      </section>

      <section className="top-section pb-5 no-img-e6">
        <div className="container-lg cl-custom">
          <div className="row">
            <div className='col-lg-5 col-sm-6 col-12 style-two'>
                  <div className='vdhvbs'>What are you looking for?</div>
                  <Link to="/signin" className="safjehbgfvehjb radio-50px">I am looking for a Mentor</Link>
                  <Link to="/signin" className="safjehbgfvehjb radio-50px">I want to become a Mentor </Link>
                  <div className="csdhvbsdk">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container-lg cl-custom">
          <div className="secdkhgbkjrs mb-2">Importance of a good Mentor ship </div>
          <div className="row">
            
            <div className="col-sm-6 col-12 mb-5">
                <div className='wg300'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</div>
            </div>

          </div>
        </div>
      </section>

    </>
  );
};
