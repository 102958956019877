import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { InputField, SelectField } from '../../configue/FormValidator';
import { API_HOST, live_token, imageUrl } from '../../configue/confifigue'
import { useAuth, getLoggedInUserData } from '../../contexts/AuthContext';
import axios from 'axios';


// Images Common File
import * as Img from '../../components/Img';

// Design File
import Commonlayout from "./Commonlayout";


export default function PageSchedule() {


    const [userLoggedIn, setUserLoggedIn] = useState(false)
    const { dispatch, state } = useAuth();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState('');
    const navigate = useNavigate();

    const [scheduleOptions, setScheduleOptions] = useState([]);



    const fetchData = async () => {
        try {
            const API_ENDPOINT = `${API_HOST}/schedules/${user.id}`;
            const HEADERS = {
                'Authorization': `Bearer ${live_token}`,
                'Content-Type': 'application/json',
            };

            const response = await axios.get(API_ENDPOINT, {
                headers: HEADERS,
            });


            if (Array.isArray(response.data) && response.data.length > 0) {
                setScheduleOptions(response.data);
            }

        } catch (error) {
            console.error('API request error:', error);
        }
    };

    useEffect(() => {
        const loggedInUser = getLoggedInUserData();
        const isUserLoggedIn = !!loggedInUser;

        if (isUserLoggedIn) {
            setUserLoggedIn(true);
            setUser(loggedInUser)

            if (user.id) {
                fetchData();
            }

        }else{
             navigate('/signin')
        }
    }, [state.userData]);





    const [slotDuration, setSlotDuration] = useState('15 Mins');
    const [selectedTimeSlot, setSelectedTimeSlot] = useState('Monday');
    const [selectedStartTime, setSelectedStartTime] = useState('12:00 Am');
    const [selectedEndTime, setSelectedEndTime] = useState('12:00 Am');





    const handleUpdateDetails = async () => {
        if ((selectedStartTime >= selectedEndTime) && (selectedStartTime !== '23:00' || selectedEndTime !== '00:00')) {
            alert('Invalid time range: Start time must be before end time.');
            return;
        }
        
        const matchingSchedule = scheduleOptions.find(schedule => schedule.day === selectedTimeSlot);
    
        if (matchingSchedule) {
            const selectedDayId = matchingSchedule.id;
    
            try {
                const API_ENDPOINT = `${API_HOST}/reschedule/${selectedDayId}/`;
                const HEADERS = {
                    'Authorization': `Bearer ${live_token}`,
                    'Content-Type': 'application/json',
                };
    
                const response = await axios.patch(API_ENDPOINT, {'mentor':user.id,'duration':slotDuration,'day':selectedTimeSlot,'start_time':selectedStartTime,'end_time':selectedEndTime}, {
                    headers: HEADERS,
                });
    
                fetchData();
            } catch (error) {
                console.error('API request error:', error);
            }
           
        } else {
            try {
                const API_ENDPOINT = `${API_HOST}/reschedule/`;
                const HEADERS = {
                    'Authorization': `Bearer ${live_token}`,
                    'Content-Type': 'application/json',
                };
    
                const response = await axios.post(API_ENDPOINT, {'mentor':user.id,'duration':slotDuration,'day':selectedTimeSlot,'start_time':selectedStartTime,'end_time':selectedEndTime}, {
                    headers: HEADERS,
                });
                fetchData();
            } catch (error) {
                console.error('API request error:', error);
            }
        }
    };
    



    return (
        <Commonlayout>

            <Helmet>
                <title>Schedule | My Mentor</title>
            </Helmet>

            {/* --- Profile Bx Start --- */}
            <div className="amib-inner-item">
                <div className="amib-768-width mt-4">
                    <div className="amib-i-header">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        Schedule Timings
                    </div>
                    <div className="amib-item pb-2">
                        <form action="">

                            <div className="row">

                                <div className="col-md-4 col-6">
                                    <div className="signinup-group sgri">
                                        <div className="group__label">Slot Duration</div>
                                        <select value={slotDuration} onChange={(e) => setSlotDuration(e.target.value)}>
                                            <option>15 Mins</option>
                                            <option>30 Mins</option>
                                            <option>45 Mins</option>
                                            <option>1 Hour</option>
                                        </select>
                                        <span className="down-icon"></span>
                                    </div>
                                </div>

                                <div className="col-md-4 col-6">
                                    <div className="signinup-group sgri">
                                        <div className="group__label">Time Slots</div>
                                        <select value={selectedTimeSlot} onChange={(e) => setSelectedTimeSlot(e.target.value)}>
                                            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => {
                                                const matchingSchedule = scheduleOptions.find(schedule => schedule.day === day);
                                                return (
                                                    <option key={day} value={day} id={matchingSchedule ? matchingSchedule.id : null}>
                                                        {day}
                                                    </option>
                                                );
                                            })}
                                        </select>

                                        <span className="down-icon"></span>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="row amib-subinner-bx mx-0 border">
                                        <div className="col-12">
                                            <div className="amib-subinner-heading">Manday Time Slot</div>
                                        </div>

                                        <div className="col-md-4 col-6">
                                            <div className="signinup-group sgri">
                                                <div className="group__label">Start Time</div>
                                                <select value={selectedStartTime} onChange={(e) => setSelectedStartTime(e.target.value)}>
                                                    <option>00:00</option>
                                                    <option>01:00</option>
                                                    <option>02:00</option>
                                                    <option>03:00</option>
                                                    <option>04:00</option>
                                                    <option>05:00</option>
                                                    <option>06:00</option>
                                                    <option>07:00</option>
                                                    <option>08:00</option>
                                                    <option>09:00</option>
                                                    <option>10:00</option>
                                                    <option>11:00</option>
                                                    <option>12:00</option>
                                                    <option>13:00</option>
                                                    <option>14:00</option>
                                                    <option>15:00</option>
                                                    <option>16:00</option>
                                                    <option>17:00</option>
                                                    <option>18:00</option>
                                                    <option>19:00</option>
                                                    <option>20:00</option>
                                                    <option>21:00</option>
                                                    <option>22:00</option>
                                                    <option>23:00</option>
                                                </select>
                                                <span className="down-icon"></span>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-6">
                                            <div className="signinup-group sgri">
                                                <div className="group__label">End Time</div>
                                                <select value={selectedEndTime} onChange={(e) => setSelectedEndTime(e.target.value)}>
                                                    <option>00:00</option>
                                                    <option>01:00</option>
                                                    <option>02:00</option>
                                                    <option>03:00</option>
                                                    <option>04:00</option>
                                                    <option>05:00</option>
                                                    <option>06:00</option>
                                                    <option>07:00</option>
                                                    <option>08:00</option>
                                                    <option>09:00</option>
                                                    <option>10:00</option>
                                                    <option>11:00</option>
                                                    <option>12:00</option>
                                                    <option>13:00</option>
                                                    <option>14:00</option>
                                                    <option>15:00</option>
                                                    <option>16:00</option>
                                                    <option>17:00</option>
                                                    <option>18:00</option>
                                                    <option>19:00</option>
                                                    <option>20:00</option>
                                                    <option>21:00</option>
                                                    <option>22:00</option>
                                                    <option>23:00</option>
                                                </select>
                                                <span className="down-icon"></span>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className='ms-auto reset-btn mt-md-3 mb-3'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                </svg>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div className="amib-save-btn-bx">
                                <button type="button" onClick={handleUpdateDetails} className="common-submit-btn">Update details</button>
                                {/* <div className="lsb-loader-btn">
                            <img src={Img.loading} alt="Please wait" />Please wait ....
                        </div> */}
                            </div>

                        </form>
                    </div>
                </div>


            </div>
            {/* --- Profile Bx End --- */}

        </Commonlayout>
    );
};
