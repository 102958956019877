import React from 'react';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

// Images Common File
// import * as Img from '../../components/Img';


import timepa1 from '../../img/timepa(1).jpg';
import timepa2 from '../../img/timepa(2).jpg';
import timepa3 from '../../img/timepa(3).jpg';
import timepa4 from '../../img/timepa(4).jpg';


export default function PageHome() {

  return (
    <>
      <Helmet>
        <title>My Mentor</title>
      </Helmet>

      <section className="bg-color-e6">
        <div className="container-lg cl-custom">
          <div className="top-top-heading">
            We help you shape your career by giving <br/>
            the mentor ship by industry professionals
          </div>
        </div>
      </section>

      <section className="top-section py-5 bg-img-three">
        <div className="container-lg cl-custom py-4">
          <div className="row justify-content-end">
            <div className='col-lg-5 col-md-6 col-12 text-center text-sm-end'>
                  <div className='vdhvbs'>What are you looking for?</div>
                  <Link to="/signin" className="ms-sm-auto safjehbgfvehjb radio-50px">I am looking for a Mentor</Link>
                  <Link to="/signin" className="ms-sm-auto safjehbgfvehjb radio-50px">I want to become a Mentor </Link>
                  <div className="ms-sm-auto csdhvbsdk">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 bg-color-e6">
        <div className="container-lg cl-custom">
          <div className="secdkhgbkjrs mb-2">Importance of a good Mentor ship </div>
          <div className="row">
            
            <div className="col-sm-6 col-12 mb-5">
                <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</div>
            </div>

            <div className='col-12'></div>

            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
              <div className='cedzegrdb'>
                <img src={timepa1} alt="" />
                <div className="">
                  <div className='syucgdavs'>Motivation</div>
                  <div className='syucgdavs-textbc'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
              <div className='cedzegrdb'>
                <img src={timepa2} alt="" />
                <div className="">
                  <div className='syucgdavs'>Advice</div>
                  <div className='syucgdavs-textbc'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
              <div className='cedzegrdb'>
                <img src={timepa3} alt="" />
                <div className="">
                  <div className='syucgdavs'>Direction</div>
                  <div className='syucgdavs-textbc'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
              <div className='cedzegrdb'>
                <img src={timepa4} alt="" />
                <div className="">
                  <div className='syucgdavs'>Support</div>
                  <div className='syucgdavs-textbc'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

    </>
  );
};
