import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { API_HOST, live_token } from '../../configue/confifigue'
import { InputField } from '../../configue/FormValidator';
import axios from 'axios';
import { useAuth, getLoggedInUserData  } from '../../contexts/AuthContext';
import FlashMessage from '../../components/FlashMessage';
// Images Common File
import * as Img from '../../components/Img';

// Css File
import '../../css/signinup.css'


const hidepassSvg = () => {
    return (
        <svg className="gri_password" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
        </svg>
    )
}

const viewpassSvg = () => {
    return (
        <svg className="gri_password" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
    )
}

export default function PageSignIn() {

    const [userLoggedIn,setUserLoggedIn] = useState(false)
    const { dispatch,state } = useAuth();
    const [isLoggedIn,setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const emailRef = useRef();
    const passwordRef = useRef();


    const [showPassword, setShowPassword] = useState(false);

const [loginSuccsess,setLoginSuccsess] = useState(false);
const [loginError,setLoginError] = useState(false);

    useEffect(() => {
        const loggedInUser = getLoggedInUserData();
        const isUserLoggedIn = !!loggedInUser;
      
        if (isUserLoggedIn) {
          setUserLoggedIn(true);
          navigate('/')
        }
    }, [state.userData]);


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };


  

    
    const handleValidation = async () => {
        setLoginSuccsess(false);
        setLoginError(false);
        emailRef.current.validate();
        passwordRef.current.validate();

        if (
            emailRef.current.getError() ||
            passwordRef.current.getError()
        ) {
            return;
        }

          try {
              const token = live_token
  
              const response = await axios.post(`${API_HOST}/user-login/`, {
                email: email,
                password : password,
              }, {
                  headers: {
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'application/json',
                  },
              });
                
              if(response.data.error === '0' && response.data.msg === 'Login successful') {
                setLoginSuccsess(true);
                localStorage.setItem('userData', JSON.stringify(response.data.userData));
                dispatch({ type: 'LOGIN', payload: response.data.userData });
                setIsLoggedIn(true)
                setTimeout(() => {
                    navigate('/');
                  }, 2000);
                }
             else{
                setLoginError(true);
             }
          } catch (error) {
              console.error('API request error:', error);
          }
      };

    return (
        <>
            
            <Helmet>
                <title>Sign In | My Mentor</title>
            </Helmet>

            <div className="login-page-back-effect"></div>
            <div className="sign-in-up-bx">

                <div className='signinup-screen active' id="login-screen">

                    <div className='signinup-header'>
                        <Link to="/" className="back-btn" title="Back to Home">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" color="#F2F1F3" width="20px"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path></svg>
                        </Link>
                        <div>
                            <div className="screen-hed">Login</div>
                            <div className="screen-pera">Access to your dashboard</div>
                        </div>
                        {/* <img className="signinup-logo" src={Img.logo_color} alt="" /> */}
                    </div>

                    <div className="login-commun-bx">
                        <a class="login-commun-btn facebook-btn" href="/signup">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    d="M480 257.35c0-123.7-100.3-224-224-224s-224 100.3-224 224c0 111.8 81.9 204.47 189 221.29V322.12h-56.89v-64.77H221V208c0-56.13 33.45-87.16 84.61-87.16 24.51 0 50.15 4.38 50.15 4.38v55.13H327.5c-27.81 0-36.51 17.26-36.51 35v42h62.12l-9.92 64.77H291v156.54c107.1-16.81 189-109.48 189-221.31z"
                                    fill-rule="evenodd" />
                            </svg>
                            <span>Facebook</span>
                        </a>
                        <a class="login-commun-btn google-btn" href="/signup">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    d="M473.16 221.48l-2.26-9.59H262.46v88.22H387c-12.93 61.4-72.93 93.72-121.94 93.72-35.66 0-73.25-15-98.13-39.11a140.08 140.08 0 01-41.8-98.88c0-37.16 16.7-74.33 41-98.78s61-38.13 97.49-38.13c41.79 0 71.74 22.19 82.94 32.31l62.69-62.36C390.86 72.72 340.34 32 261.6 32c-60.75 0-119 23.27-161.58 65.71C58 139.5 36.25 199.93 36.25 256s20.58 113.48 61.3 155.6c43.51 44.92 105.13 68.4 168.58 68.4 57.73 0 112.45-22.62 151.45-63.66 38.34-40.4 58.17-96.3 58.17-154.9 0-24.67-2.48-39.32-2.59-39.96z" />
                            </svg>
                            <span>Google</span>
                        </a>
                    </div>

                    <div className="login-separator">
                        <span>Or</span>
                    </div>

                    <form className="">

                        <div className="row">

                            <div className="col-12">
                                <div className="signinup-group">
                                    <div className="group__label">Your email id</div>
                                    <InputField
                                        name="Email"
                                        type="email"
                                        placeholder="you@example.com"
                                        required={true}
                                        value={email}
                                        onChange={handleEmailChange}
                                        ref={emailRef}
                                    />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="signinup-group sgri">
                                    <div className="group__label">Password</div>
                                    <InputField
                                        name='Password'
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Enter password"
                                        required={true}
                                        value={password}
                                        onChange={handlePasswordChange}
                                        ref={passwordRef}
                                    />
                                    <span className={`group_right_icon ${showPassword ? "active" : ""}`} onClick={togglePasswordVisibility}>
                                        {showPassword ? viewpassSvg() : hidepassSvg()}
                                    </span>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="d-flex align-items-center" style={{ marginTop: "-10px" }}>
                                    <div className="signinup-group-checkmark">
                                        <input className="d-none" type="checkbox" id="checkmark" />
                                        <label className="checkmark-lable" htmlFor="checkmark"><span className="checkmark-icon"></span>Remember Me</label>
                                    </div>
                                    <Link className="forget-link" to="/forgotpassword">Forgot password?</Link>
                                </div>
                            </div>

                        </div>

                        <button type="button" onClick={handleValidation} className="login-submit-btn">Continue</button>

                        {/* <div className="lsb-loader-btn">
                            <img src={Img.loading} alt="" />Please wait ....
                        </div> */}

                        <div className="signinuptogleblink">
                            Don’t have an account?
                            <Link to='/signup' className="siulink">Register</Link>
                        </div>
                        {loginSuccsess && <FlashMessage type="success" isvisible={true} message="Login successfull" />}
                        {loginError && <FlashMessage type="error" isvisible={true} message="Email or password did not match" />}

                    </form>

                </div>

            </div>
        </>
    );
};