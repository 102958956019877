import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate, json } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { API_HOST, live_token } from '../../configue/confifigue'
import { useAuth, getLoggedInUserData } from '../../contexts/AuthContext';
// import RazorpayCheckout from '../../configue/Razorpaycheckout';
import EmptyBox from '../../components/EmptyBox';
import axios from 'axios';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Images Common File
import * as Img from '../../components/Img';

const time = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
    </svg>
  )
}


const remove = () => {
  return (
    <svg style={{ width: "18px", height: "18px" }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
    </svg>
  )
}

export default function PageAppointment() {

  const { dispatch, state } = useAuth();
  const [user, setUser] = useState('');
  const navigate = useNavigate();

  const [startTime, setstartTime] = useState('');
  const [endtTime, setEndTime] = useState('');
  const [day, setDay] = useState('');
  const [timeSlots, setTimeSlots] = useState([]);
  const [bookedTimeSlots, setBookedTimeSlots] = useState([]);

  const [selectedSlot, setSelectedSlot] = useState(null);
  const [slotError, setSlotError] = useState(false);

  const [pageNotFound, setPageNotFound] = useState(false);

  const isTimeSlotBooked = (time) => bookedTimeSlots.some(bookedTime => bookedTime.slice(0, 5) === time.slice(0, 5));


  useEffect(() => {
    const loggedInUser = getLoggedInUserData();
    const isUserLoggedIn = !!loggedInUser;

    if (!isUserLoggedIn) {
      navigate('/signin')
    } else {
      setUser(loggedInUser);
    }
  }, [state.userData]);

  const { id } = useParams();
  const [mentor, setMentor] = useState(null);



  const fetchDataTwo = async (today) => {
    setPageNotFound(false);
    try {
      const secondApiUrl = `${API_HOST}/get-currentdate-slots/${id}/${today}/`;
      const secondApiResponse = await axios.get(secondApiUrl, {
        headers: {
          'Authorization': `Bearer ${live_token}`,
          'Content-Type': 'application/json',
        },
      });
      const { time_slots, booked_time_slots, start_time, end_time, day } = secondApiResponse.data;


      const startDateTime = new Date(start_time);
      const endDateTime = new Date(end_time);

      const formattedStartTime = startDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      const formattedEndTime = endDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

      setstartTime(formattedStartTime)
      setEndTime(formattedEndTime)
      setDay(day);

      const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      const pastSlots = time_slots.filter((slot) => new Date(`1900-01-01 ${slot}`) < new Date(`1900-01-01 ${currentTime}`));
      const allDisabledSlots = [...pastSlots, ...booked_time_slots];

      setTimeSlots(time_slots);
      setBookedTimeSlots(allDisabledSlots);

    } catch (error) {
      console.error('API request error:', error);
      setPageNotFound(true);
    }
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = live_token;
        let apiUrl = `${API_HOST}/update-user-data/${id}`;

        const response = await axios.get(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        setMentor(response.data);
      } catch (error) {
        console.error('API request error:', error);
      }
    };

    if (id) {
      fetchData();
      const today = new Date().toLocaleDateString('en-US', { weekday: 'long' });
      fetchDataTwo(today);
    }
  }, [id]);




  const [startDate, setStartDate] = useState(new Date());
  const [isSummaryBx, setisSummaryBx] = useState(false);

  const handleDateChange = (date) => {
    setStartDate(date);
  };
  const handleSummaryBx = () => {
    setisSummaryBx(!isSummaryBx);
  };


  useEffect(() => {

    const today = startDate.toLocaleDateString('en-US', { weekday: 'long' });
    fetchDataTwo(today);
  }, [startDate]);



  const handleSlotClick = (index) => {
    if (!isTimeSlotBooked(timeSlots[index])) {
      setSelectedSlot(index);
    } else {
      setSelectedSlot(null);
    }
  };


  // PAYMENT CODE 
  // ====================

  const PaymentSuccsessHandler = async (mentor_id, mentee_id, date, day, timeslot, price, payment_id) => {
    try {
      const URl = `${API_HOST}/payment-success/`;
      const response = await axios.post(URl, {
        mentor: mentor_id,
        mentee: mentee_id,
        date: date,
        day: day,
        time: timeslot,
        amount: price,
        payment_id: payment_id,
      }, {
        headers: {
          'Authorization': `Bearer ${live_token}`,
          'Content-Type': 'application/json',
        },
      });
      const today = startDate.toLocaleDateString('en-US', { weekday: 'long' });
      fetchDataTwo(today);
      navigate('/thankyou', { state: { payment_id, date, timeslot } });
    } catch (error) {
      console.error('API request error:', error);
    }
  };


  const openRazorpayModal = (order_id, total_amount) => {
    const options = {
      key: 'rzp_test_Am5N5FCGxm4p2Y',
      amount: total_amount,
      currency: 'INR',
      name: 'My Mentor',
      description: 'Payment for appointment',
      order_id: order_id,
      handler: function (response) {
        if (response.razorpay_payment_id) {
          const today = startDate.toLocaleDateString('en-US', { weekday: 'long' });
          PaymentSuccsessHandler(id, user.id, startDate, today, timeSlots[selectedSlot], mentor.price, response.razorpay_payment_id)
        } else {
          console.log('Payment canceled or failed.');
        }
      },
      prefill: {
        name: `${user.first_name} - ${user.last_name}`,
        email: user.email,
        contact: user.phone_number,
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };




  const initiateRazorpayPayment = async (total_price) => {
    try {
      const URl = `${API_HOST}/payment/`;
      const response = await axios.post(URl, {
        amount: total_price
      }, {
        headers: {
          'Authorization': `Bearer ${live_token}`,
          'Content-Type': 'application/json',
        },
      });

      openRazorpayModal(response.order_id, mentor.price);
    } catch (error) {
      console.error('API request error:', error);
    }
  };


  const handleSlotSelection = () => {
    if (selectedSlot !== null) {
      setSlotError(false);
      initiateRazorpayPayment(mentor.price)
    } else {
      setSlotError(true);
    }
  };


  return (
    <>

      <Helmet>
        <title>Checkout | My Mentor</title>
      </Helmet>

      <section>
        <div className="breadcrumb-outer">
          <div className="container-lg cl-custom">
            <div className="breadcrumb-bx">
              <Link className="breadcrumb-link" to="/">Home<i className="fa fa-angle-right" aria-hidden="true"></i></Link>
              <Link className="breadcrumb-link" to="/detail">Steve Arlin<i className="fa fa-angle-right" aria-hidden="true"></i></Link>
              <Link className="breadcrumb-link breadcrumb-active">Checkout</Link>
            </div>
          </div>
        </div>
      </section>

      <section className="py-4">
        <div className="container-lg cl-custom">

          <div className="checkout-main-bx">

            <div className="cmb-left">

              {/* --- */}
              <div className="row">

                <div className="col-12 mb-3">
                  <div className="common-heading-bx chb-two">
                    <div className="common-heading">Logged in as</div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="checkout-pic account-left-pic">
                    {/* <img className="alp-img" src={Img.user} alt="" /> */}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                    </svg>
                    <div className="alp-data">
                      <div className="alp-name-lable">{user.first_name} {user.last_name}</div>
                      <div className="alp-email-lable">{user.email}</div>
                    </div>
                  </div>
                </div>

              </div>
              {/* --- */}

              <div className="my-5"></div>

              {/* --- */}
              <div className="row">

                <div className="col-12 mb-3">
                  <div className="common-heading-bx chb-two">
                    <div className="common-heading">Available Slot</div>
                    <div className="common-sub-heading">
                      {time()} {day} ({startTime} - {endtTime})
                    </div>


                    <div className="signinup-group datepicker-bx appointment-bx">
                      <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={handleDateChange}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                        </svg>}
                      />
                    </div>
                  </div>
                </div>


                {pageNotFound ? (
                 <EmptyBox eh="No slots found 🥺" esh="No slots found on this date." />
                ) : (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <div className="slot-box">
                          {timeSlots.map((time, index) => (
                            <div
                              key={index}
                              className={`slot-time ${isTimeSlotBooked(time) ? 'slot-disable' : ''} ${selectedSlot === index ? 'selected-slot' : ''}`}
                              onClick={() => handleSlotClick(index)}
                            >
                              {time}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {slotError && <span className="form-error-msg">Please select the slot</span>}
                  </>
                )}

              </div>
              {slotError && <span className="form-error-msg">Please select the slot</span>}
              {/* --- */}

              <div className="my-5"></div>

              {/* --- */}
              {/* <div className="row">

              <div className="col-12 mb-1">
                <div className="common-heading-bx chb-two">
                  <div className="common-heading">Selected Slot <span>(01/03)</span> </div>
                </div>
              </div>

              <table className="custom-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                    <tr>
                      <td>1</td>
                      <td>29/02/2024</td>
                      <td>09:30 AM</td>
                      <td><div className="common-btn-item cbi-fill cbi-delete">{remove()}</div></td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>01/03/2024</td>
                      <td>11:30 AM</td>
                      <td><div className="common-btn-item cbi-fill cbi-delete">{remove()}</div></td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>03/03/2024</td>
                      <td>05:30 PM</td>
                      <td><div className="common-btn-item cbi-fill cbi-delete">{remove()}</div></td>
                    </tr>
                </tbody>
              </table>

            </div>
     */}

            </div>

            <div className="cmb-right">

              {/* --- */}
              <div className={` summary-bx ${isSummaryBx ? 'active' : ''} `}>
                <span style={{ top: "10px" }} onClick={handleSummaryBx} className="close-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="38" d="M368 368L144 144M368 144L144 368" /></svg>
                </span>
                <div className="summary-heading">
                  Mentor Profile
                </div>
                <div className="summary-data-bx">
                  <div className="summary-data-item">
                    <div className="sdi-inner sdi-img">
                      <img src={mentor?.profile_pic || Img.user} alt="" />
                      <div>{mentor?.first_name} {mentor?.last_name} <span>Rs.{mentor?.price}</span> </div>
                    </div>
                  </div>
                  {/* <div className="summary-data-item">
                        <div className="sdi-inner">
                            <div>Service fee</div>
                            <div>Rs. 100000</div>
                        </div>
                        <div className="sdi-inner">
                            <div>Sales tax</div>
                            <div>Free</div>
                        </div>
                    </div> */}
                  <div className="summary-data-item">
                    <div className="sdi-inner sdi-total">
                      <div>Total</div>
                      <div>Rs.{mentor?.price}</div>
                    </div>
                  </div>
                </div>
              </div>
              {/* --- */}

              {/*  */}
              <div className="summary-bottom-bx">

                <div onClick={handleSummaryBx}>
                  Mentor Name
                  <span>
                    Rs. 499.00
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                  </span>
                </div>

                <div>
                  <button type="button" onClick={handleSlotSelection} className="common-submit-btn">Proceed to pay</button>
                  {/* <RazorpayCheckout/> */}
                  {/* <div className="lsb-loader-btn">
                    <img src={Img.loading} alt="" />Please wait ....
                </div> */}
                </div>

              </div>
              {/*  */}

            </div>

          </div>

        </div>
      </section>

      {/* --- Checkout Page overlay div --- */}
      <div className={`checkout-overlay ${isSummaryBx ? 'checkout-active' : ''} `} onClick={handleSummaryBx}></div>

      <div className="pt-5"></div>
     
    </>
  )
}
