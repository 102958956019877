import React, { useState, useEffect } from 'react';
import { Link, NavLink } from "react-router-dom";
import { useAuth, getLoggedInUserData } from '../../contexts/AuthContext';
import { API_HOST, live_token } from '../../configue/confifigue'
import { useHeader } from '../../contexts/HeaderProvider';
import axios from 'axios';


// Context File
import { useMenu } from './MenuContext';

// Design File
import Sidemenu from './Sidemenu';

// Images Common File
import * as Img from '../Img';

// Css Files
import '../../css/header.css';

export default function Header(props) {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const { state } = useAuth();

  // --- Header Js Start --- //
  const [headerSticky, setheaderSticky] = useState("");

  useEffect(() => {
    const loggedInUser = getLoggedInUserData();
    const isUserLoggedIn = !!loggedInUser;

    if (isUserLoggedIn) {
      setUserLoggedIn(true);
    }
  }, [state.userData]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setheaderSticky("active");
      } else {
        setheaderSticky("");
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  // --- Header Js End --- //


  // --- --- //
  const { toggleMenu } = useMenu();
  // --- --- //

  return (
    <>
      <header className={`maxwidth1920 custom-header ${headerSticky} ${props.className}`}>

        {/* <div className="ch-top-bx">

            <div className="ctb-contact">
              <a href="tel:+918128181082" className="contact-item">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                </svg>
                +91 812 818 1082
              </a>
              <a href="mailto:info@mymentor.com" className="contact-item">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                </svg>
                Info@mymentor.com
              </a>
            </div>

          </div> */}

        <div className="ch-inner">

          {/* Website Logo Start */}
          <Link to="/" className="ch-left">
            <div className="ch-logo">
              <img src={Img.logo_color} alt="" />
            </div>
          </Link>
          {/* Website Logo End */}


          {/* Website Menu Start */}
          <div className="ch-menu-bx">

            {/* <Link to="/become-mentor" className="cmb-item">Become a Mentor <div className="cmb-item-line"></div></Link> */}
            {/* <Link to="/looking-mentor" className="cmb-item">Find a Mentor <div className="cmb-item-line"></div></Link> */}
            <NavLink to="/about" className="cmb-item">About <div className="cmb-item-line"></div></NavLink>
            <NavLink to="/index2" className="cmb-item">Our Mentors  <div className="cmb-item-line"></div></NavLink>
            <NavLink to="/contact" className="cmb-item">Get In Touch <div className="cmb-item-line"></div></NavLink>

          </div>
          {/* Website Menu End */}


          <div className="ch-right">

            {/* Signup Btns Start */}
            <div className="signinup-btn-bx">
              {userLoggedIn ? (
                <Link to="/account" className="sip-btn account-sip-btn">
                  <img src={Img.user} alt="" /> Account
                </Link>
              ) : (
                <>
                  <Link to="/signin" className="sip-btn login-sip-btn">
                    Login
                  </Link>
                  <Link to="/signup" className="sip-btn register-sip-btn" sbdata="Sign Up">
                    <span>Sign Up</span>
                  </Link>
                </>
              )}
            </div>
            {/* Signup Btns End */}

            <div className="menu-icon-bx" onClick={toggleMenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>

          </div>

        </div>

      </header>

      <Sidemenu />
    </>
  )
}
