import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { API_HOST, live_token } from '../../configue/confifigue'
import axios from 'axios';
// Design File
import MentorsSliderDemo from '../Slider/MentorsSliderDemo';
import TestimonialsSlider from '../Slider/TestimonialsSlider';

// Images
import demo1 from '../../img/demo/avatar1.png';
import demo2 from '../../img/demo/avatar2.png';
import demo3 from '../../img/demo/avatar3.png';
import demo4 from '../../img/demo/avatar4.png';


// Images Common File
import * as Img from '../../components/Img';

const start = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
      <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
    </svg>
  )
}

const location = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
    </svg>
  )
}

export default function PageMetorDetail() {

  const { id } = useParams();
  const [mentor, setMentor] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = live_token;
        let apiUrl = `${API_HOST}/update-user-data/${id}`;


        const response = await axios.get(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        console.log(response.data)
        setMentor(response.data);


      } catch (error) {
        console.error('API request error:', error);
      }
    };
    if (id) {
      fetchData();
    }


  }, [id]);


  return (
    <>

      <Helmet>
        <title>Mentor Name | My Mentor</title>
      </Helmet>

      <section>
        <div className="breadcrumb-outer">
          <div className="container-lg cl-custom">
            <div className="breadcrumb-bx">
              <Link className="breadcrumb-link" to="/">Home<i className="fa fa-angle-right" aria-hidden="true"></i></Link>
              <Link className="breadcrumb-link breadcrumb-active">Mentor Name</Link>
            </div>
          </div>
        </div>
      </section>

      <section className="py-4">
        <div className="container-lg cl-custom pb-5">

          <div className="profiledetailbx">
            <div className="pdb-left">
              <img className="pdb-img" src={mentor && mentor.profile_pic ? mentor.profile_pic : demo1} alt="" />
            </div>
            <div className="pdb-right">

              <div className="pdb-name">{mentor?.first_name} {mentor?.last_name}</div>

              <div className="pdb-start-rating">
                <div className="pdb-star star4">{start()}{start()}{start()}{start()}{start()}</div>
                <div className="pdb-star-num">4.9</div>
              </div>

              <div className="pdb-speciality">English</div>

              <div className="pdb-location">{location()}{mentor?.address}</div>

              <div className="pdb-about-text">{mentor?.about_me}</div>


              <div className="pdb-price">
                <div>Rs.{mentor?.price}</div>
              </div>

              <Link to={mentor && mentor.id ? `/checkout/${mentor.id}` : '/checkout'} key={mentor && mentor.id} className="common-btn-item cbi-fill cb-big pdb-btn">
                <span>Schedule Your Time</span>
              </Link>


            </div>
          </div>

        </div>
      </section>


      <section>
        <div className="container-lg cl-custom relative pb-5">
          <div className="common-heading-bx text-center">
            <div className="common-heading common-heading-small">Customer Reviews</div>
          </div>
          <div className="add-reviews-bx">
            <div className="arb-left">

              <div className="pdb-start-rating justify-content-center">
                <div className="pdb-star star4">{start()}{start()}{start()}{start()}{start()}</div>
                <div className="pdb-star-num">4.9</div>
              </div>
              <p>Write your review</p>


              {/* <div className="pdb-start-rating justify-content-center">
              <div className="pdb-star">{start()}{start()}{start()}{start()}{start()}</div>
            </div>
            <p>Be the first to write a review</p> */}

            </div>
            <div className="arb-right">
              <div data-bs-toggle="modal" data-bs-target="#addreviewmodal" className="common-btn-item cbi-outline cb-big mx-auto" cbdata="Write a review"><span>Write a review</span></div>
            </div>
          </div>
        </div>
      </section>


      <section className="py-5 bg-color-lightgray">
        <div className="container-lg cl-custom relative py-4">
          <div className="common-heading-bx text-center">
            <div className="common-top-heading">What our customer are saying?</div>
            <div className="common-heading">Customer Reviews</div>
          </div>
          <TestimonialsSlider />
        </div>
      </section>

      <section className="py-5">
        <div className="container-lg cl-custom relative py-4">
          <div className="common-heading-bx mb-5 mb-md-0 pb-2 pb-md-0">
            <div className="common-heading">Recommended Mentor's</div>
          </div>
          <MentorsSliderDemo />
        </div>
      </section>

      <div className="pt-5"></div>

    </>
  )
}
