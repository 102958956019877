
const getApiHost = () => {
    if (window.location.hostname === 'localhost' && window.location.port === '3000') {
      return 'http://127.0.0.1:8000/api';
    }else{
      return 'https://mymentorbackend.pmcommu.in/api';
    }
  };
  
  const getLiveToken = () => {
    if (window.location.hostname === 'localhost' && window.location.port === '3000') {
      return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzM3ODA3MzA0LCJpYXQiOjE3MDYyNzEzMDQsImp0aSI6IjdkOTMyN2Y1OTNlYTQxMzk5MzcxMWY2YWYwMjI0Zjk0IiwidXNlcl9pZCI6MX0.RE1u1W22yPW9drDv95ZxUAO088kziThrGnt-dVBgY0Y';
    } else {
      return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzM4MTUwOTYzLCJpYXQiOjE3MDY2MTQ5NjMsImp0aSI6IjMxODhjZGJiZGZkODQ2ZGJhOTRlODYwN2IzYjgxYWIwIiwidXNlcl9pZCI6MX0.J7fDYg0NWZfK1OMQ7tHdt7OU69nDj4DaJGleXGkTmCQ';
    }
  };
  

  const getImagePath = () =>{
    if (window.location.hostname === 'localhost' && window.location.port === '3000') {
      return 'http://127.0.0.1:8000';
    }else{
      return 'https://mymentorbackend.pmcommu.in';
    }
  }

 
 
  
  export const API_HOST = getApiHost();
  export const live_token = getLiveToken();
  export const imageUrl = getImagePath();


  