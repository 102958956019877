import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";


export default function PagePrivacy() {
  return (
    <>

      <Helmet>
        <title>Privacy Policy | My Mentor</title>
      </Helmet>

      <section>
        <div className="breadcrumb-outer">
          <div className="container-lg cl-custom">
            <div className="breadcrumb-bx">
                <Link className="breadcrumb-link" to="/">Home<i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                <Link className="breadcrumb-link breadcrumb-active">Privacy Policy</Link>
            </div>
          </div> 
        </div>
      </section>

      <section className="pt-4 pb-3">
          <div className="container-lg cl-custom legal-content">
            <div className="common-heading-bx text-center mt-4 pb-2">
                <div className="common-heading">Privacy Policy</div>
            </div>

            <div className="legal-content mt-4">
           
              <div className="main-sub-lable">Lorem Ipsum is simply dummy text</div>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

              <div className="main-sub-lable">Lorem Ipsum is simply dummy text</div>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

              <div className="main-sub-lable">Lorem Ipsum is simply dummy text</div>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

              <div className="main-sub-lable">How to contact us</div>
              <p>If you have any questions or concerns regarding this Privacy Policy, please email us at <a href="mailto:info@mymentor.com" target="_blank" rel="noreferrer">info@mymentor.com</a></p>
            </div>

          </div>
      </section>

      <div className="pt-5"></div>

    </>
  );
};
