import React from 'react';
// import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

// Images Common File
// import * as Img from '../../components/Img';


export default function PageHome() {

  return (
    <>
      <Helmet>
        <title>My Mentor</title>
      </Helmet>

      <section className="top-section py-5">
        <div className="container-lg cl-custom py-5">
          <div className="top-heading py-lg-5">
            How mentors can help build <br/> a successful mentoring relationship
          </div>
        </div>
      </section>

      <section className="py-5 bg-color-e6">
        <div className="container-lg cl-custom">

          <div className="row">
            
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
              <div className='data-div-bx'>
                <div>Setting clear expectations</div>
                <div>At the start of the mentoring relationship, it is important for both the mentor and the mentee to have a clear understanding of what each expects from the relationship, including the goals and objectives of the mentee, and the role and responsibilities of the mentor.</div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
              <div className='data-div-bx'>
                <div>Active listening</div>
                <div>A good mentor should be an active listener and provide an open, non-judgmental space for the mentee to share their thoughts, concerns and questions. This can help the mentee feel comfortable and onfident in sharing their ideas.</div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
              <div className='data-div-bx'>
                <div>Providing honest feedback</div>
                <div>Mentors should provide mentees with honest, constructive feedback on their performance and progress. This can help the mentee identify areas for improvement and set goals for themselves.</div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
              <div className='data-div-bx'>
                <div>Offering a diverse perspective</div>
                <div>The mentor should be able to provide the mentee with a different perspective on their work, industry or life in general. A mentor can offer unique insights, and a diverse perspective that may help mentees to understand problems and opportunities in a different way.</div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
              <div className='data-div-bx'>
                <div>Setting clear expectations</div>
                <div>At the start of the mentoring relationship, it is important for both the mentor and the mentee to have a clear understanding of what each expects from the relationship, including the goals and objectives of the mentee, and the role and responsibilities of the mentor.</div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
              <div className='data-div-bx'>
                <div>Active listening</div>
                <div>A good mentor should be an active listener and provide an open, non-judgmental space for the mentee to share their thoughts, concerns and questions. This can help the mentee feel comfortable and onfident in sharing their ideas.</div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
              <div className='data-div-bx'>
                <div>Providing honest feedback</div>
                <div>Mentors should provide mentees with honest, constructive feedback on their performance and progress. This can help the mentee identify areas for improvement and set goals for themselves.</div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
              <div className='data-div-bx'>
                <div>Offering a diverse perspective</div>
                <div>The mentor should be able to provide the mentee with a different perspective on their work, industry or life in general. A mentor can offer unique insights, and a diverse perspective that may help mentees to understand problems and opportunities in a different way.</div>
              </div>
            </div>

          </div>
        </div>
      </section>

    </>
  );
};
