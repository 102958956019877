import React from 'react';
import {Link, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet-async";

import iconsuccess from '../../img/icons/icon-success.png'

export default function PageThankyou() {
  const location = useLocation();
  const { state } = location;

  const paymentId = state?.payment_id;
  
  const date = state?.date instanceof Date ? state.date.toLocaleDateString('en-US') : state?.date;

  const timeslot = state?.timeslot;
  return (
    <>

    <Helmet>
        <title>Appointment booked Successfully! | My Mentor</title>
    </Helmet>

    <section>
      <div className="breadcrumb-outer">
        <div className="container-lg cl-custom">
          <div className="breadcrumb-bx">
              <Link className="breadcrumb-link" to="/">Home<i className="fa fa-angle-right" aria-hidden="true"></i></Link>
              <Link className="breadcrumb-link breadcrumb-active">Appointment booked Successfully!</Link>
          </div>
        </div> 
      </div>
    </section>

    <section className="py-4">
      <div className="container-lg cl-custom">
        <div className="processing">
            <div className="common-heading-bx text-center pb-2">
                <div className="common-heading"> Appointment booked Successfully!</div>
            </div>

            <img className="pro-img-bx" src={iconsuccess} alt="" />

            <div className="p-order-id">Payment Id : {paymentId}</div>
            <div className="p-order-text">Appointment has been booked on  {date}  -  {timeslot} </div>
            {/* <div className="p-order-text">Note: If your appointment is confirmed, you will be notified through your email id or notification.</div>
            <div className="p-order-text">If the appointment with the guide is not confirmed on your selected date, your payment amount will be refunded.</div> */}
            <Link to="/account" className="common-btn-item cbi-fill mx-auto mt-3"> <span>Go to account</span></Link>
        </div>
      </div>
    </section>
      
    </>
  )
}
