import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import demo1 from '../../img/demo/avatar1.png';
import demo2 from '../../img/demo/avatar2.png';
import demo3 from '../../img/demo/avatar3.png';
import demo4 from '../../img/demo/avatar4.png';

// Images Common File
import * as Img from '../../components/Img';


const start = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
      <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
    </svg>
  );
};

const location = () => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
      </svg>
  )
}

export default function  MentorsSlider() {

  const Mentorsslickslider = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    swipeToSlide: true,
    speed: 500,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "15%",
        },
      },
      {
        breakpoint: 361,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "10%",
        },
      },
    ],
  };

  return (
    <Slider className="mt-4" {...Mentorsslickslider}>
     
      <div className="slider-item-outer">
          <div className="mentor-card-item">
              <div className="mci-img">
                  <img src={demo1} alt="" />
              </div>
              <div className="nci-subject">English</div>
              <div className="nci-name">Rahul Patel</div>
              <div className="nci-star">{start()}4.7</div>
              <div className="nci-location">{location()}Washington Square Park, Washington Square, New York, NY, USA</div>
          </div>
      </div>
     
      <div className="slider-item-outer">
          <div className="mentor-card-item">
              <div className="mci-img">
                  <img src={demo2} alt="" />
              </div>
              <div className="nci-subject">English</div>
              <div className="nci-name">Rahul Patel</div>
              <div className="nci-star">{start()}4.7</div>
              <div className="nci-location">{location()}Washington Square Park, Washington Square, New York, NY, USA</div>
          </div>
      </div>
     
      <div className="slider-item-outer">
          <div className="mentor-card-item">
              <div className="mci-img">
                  <img src={demo3} alt="" />
              </div>
              <div className="nci-subject">English</div>
              <div className="nci-name">Rahul Patel</div>
              <div className="nci-star">{start()}4.7</div>
              <div className="nci-location">{location()}Washington Square Park, Washington Square, New York, NY, USA</div>
          </div>
      </div>
     
      <div className="slider-item-outer">
          <div className="mentor-card-item">
              <div className="mci-img">
                  <img src={demo4} alt="" />
              </div>
              <div className="nci-subject">English</div>
              <div className="nci-name">Rahul Patel</div>
              <div className="nci-star">{start()}4.7</div>
              <div className="nci-location">{location()}Washington Square Park, Washington Square, New York, NY, USA</div>
          </div>
      </div>

      <div className="slider-item-outer">
          <div className="mentor-card-item">
              <div className="mci-img">
                  <img src={Img.user} alt="" />
              </div>
              <div className="nci-subject">English</div>
              <div className="nci-name">Rahul Patel</div>
              <div className="nci-star">{start()}4.7</div>
              <div className="nci-location">{location()}Washington Square Park, Washington Square, New York, NY, USA</div>
          </div>
      </div>

    </Slider>
  );
};
